<template>
  <div
    v-if="showQuickLinks"
    id="quick-links-bar"
    :class="barThemeClasses"
    class="secondary-bar"
  >
    <div class="navbar-container main-menu-content">
      <ul class="nav navbar-nav">
        <li
          v-if="model === 'user' && projectName !== 'PS'"
          class="nav-item nav-link d-flex align-items-center font-weight-bold mx-1"
        >
          {{ projectName }} User {{ Id }}
        </li>
        <li
            v-if="model === 'survey'"
            class="nav-item nav-link d-flex align-items-center font-weight-bold mx-1"
        >
          Survey {{ Id }}
        </li>
        <li
            v-if="model === 'offer'"
            class="nav-item nav-link d-flex align-items-center font-weight-bold mx-1"
        >
          Offer {{ Id }}
        </li>
        <template v-if="canAccess($route.permission || $route.name)">
          <li
            v-for="quickLink in quickLinks"
            :key="quickLink.path"
            :class="{ active: quickLink.type !== 'external' && useActiveLink && quickLink.name === $route.name }"
            class="nav-item d-flex"
          >
            <template v-if="quickLink.type === 'external'">
              <a class="ml-25" :target="quickLink.target" :href="quickLink.url">
                {{ getExternalTitle(quickLink) }}
              </a>
            </template>
            <template v-else>
              <a v-if="quickLink.type === 'function'" @click.prevent="invokeMethod(quickLink.path)" class="ml-25">
                {{ quickLink.meta.title }}
              </a>
              <router-link
                v-else
                :to="generateLink(quickLink)"
                class="nav-link d-flex align-items-center"
              >
                {{ getTitle(quickLink) }}
              </router-link>
            </template>
          </li>
        </template>
      </ul>
    </div>

    <b-modal
        ref="user_profiling_basics_modal"
        size="lg"
        title="User Profiling Basics"
    >
      <UserProfilingBasics :userProfilingBasics="userProfilingBasics"/>
    </b-modal>

    <b-modal ref="click_breakdown_modal" title="Clicks Breakdown" hide-footer>
      <table class="table table-striped">
        <thead>
        <tr>
          <th>Click Count with Response Status</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="item in clickBreakdown" :key="item.name">
          <td>{{ item.clicks_ct }} {{ item.name }} ({{ item.response_status }})</td>
        </tr>
        </tbody>
      </table>
    </b-modal>
  </div>
</template>
<script>
import useAppConfig from '@core/app-config/useAppConfig'
import UserProfilingBasics from '@/views/users/UserDetails/UserProfilingBasics.vue'

export default {
  components: {UserProfilingBasics},
  props: {
    useActiveLink: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      activeItem: null,
      userProfilingBasics: null,
      clickBreakdown: null
    }
  },
  computed: {
    quickLinks () {
      const _this = this
      return this.$store.getters['app/quickLinks'].filter(quickLink => _this.canAccess(quickLink.permission || quickLink.name))
    },
    showQuickLinks () {
      return this.$store.getters['app/quickLinkProperties'].display
    },
    projectName () {
      return this.$store.getters['app/quickLinkProperties'].project?.toUpperCase() ?? ''
    },
    model () {
      return this.$store.getters['app/quickLinkProperties'].model
    },
    Id () {
      return this.$store.getters['app/quickLinkProperties']?.id ?? null
    },
    themeSkin () {
      const { skin } = useAppConfig()
      return skin.value
    },
    barThemeClasses () {
      return `${this.themeSkin}-theme-quick-link-styles`
    }
  },
  methods: {
    showUserProfilingBasicsModal () {
      this.$http
        .get(`/api/user-profiling/${ this.Id }/basics`)
        .then((response) => {
          this.$refs['user_profiling_basics_modal'].show()
          this.userProfilingBasics = response.data.data
        })
        .catch((errors) => {
          if (errors && errors.response && errors.response.data) {
            this.alertError(errors.response.data.errors)
          }
        })
    },
    showClicksBreakdownModal () {
      this.$http.get(`/api/surveys/${this.Id}/click-breakdown`)
        .then(response => {
          this.clickBreakdown = response.data
          this.$refs['click_breakdown_modal'].show()
        })
        .catch(error => {
          console.error('Failed to fetch click breakdown:', error)
        })
    },
    getTitle (quickLink) {
      return quickLink.meta?.title ?? quickLink.name
    },
    getExternalTitle (externalLink) {
      const prefix = externalLink.url ? '' : 'No '
      return prefix + externalLink.title
    },
    generateLink (routeItem) {
      const routeObject = { name: routeItem.name, params: {}, query: {} }
      const mainRoute = routeItem.meta?.main

      // Define mappings for model and associated parameter names
      const modelIdMap = {
        user: 'userId',
        survey: 'surveyId',
        offer: 'offerId'
      }

      const idParam = modelIdMap[this.model] || 'userId'

      // Set `params` or `query` based on `mainRoute` flag
      if (mainRoute) {
        routeObject.params[idParam] = this.Id
      } else {
        const queryKey = routeItem.meta?.queryFilterObject ? `filter[${idParam}]` : `filter${idParam.charAt(0).toUpperCase() + idParam.slice(1)}`
        routeObject.query[queryKey] = this.Id
      }

      // Add `queryChatFilters` to the route query
      if (routeItem.meta?.queryChatFilters) {
        Object.entries(routeItem.meta.queryChatFilters).forEach(([key, value]) => {
          if (key === 'by_user_id') {
            routeObject.query['filters["user_id"]'] = this.Id
          } else if (Array.isArray(value)) {
            value.forEach((item, index) => {
              routeObject.query[`filters["${key}"][${index}]`] = item
            })
          } else {
            routeObject.query[`filters["${key}"]`] = value
          }
        })
      }

      // Force reload if navigating to the same route
      if (routeItem.name === this.$route.name) {
        routeObject.query['forceReload'] = new Date().getTime()
      }

      return routeObject
    }
  },
  created () {
    // Watch Project value
    this.$store.watch(
      () => this.$store.state.app.quickProperties.project,
      (newValue) => {
        if (newValue) {
          this.$store.commit('app/SET_QUICK_LINKS')
        }
      }
    )

    // Watch Model value
    this.$store.watch(
      () => this.$store.state.app.quickProperties.model,
      (newValue) => {
        if (newValue) {
          this.$store.commit('app/SET_QUICK_LINKS')
        }
      }
    )
  }
}
</script>

<style lang="scss">
.secondary-bar {
  margin: 10px 0;
  //border-radius: 10px;
}

.light-theme-quick-link-styles.box-shadowed {
  box-shadow: 0 0px 2px 0 rgba(34, 41, 47, 0.75);
}

.dark-theme-quick-link-styles.box-shadowed {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.75);
}

.dark-theme-quick-link-styles {
  background: #283046 !important;
}

.light-theme-quick-link-styles {
  background: #ffffff;
}

.secondary-bar .navbar-nav {
  flex-direction: row;
}

.secondary-bar a,
.secondary-bar a:hover {
  border-radius: 4px;
  margin-right: 0.215rem !important;
  padding: 0.3rem 1.25rem !important;
}

.dark-theme-quick-link-styles.secondary-bar a {
  color: white !important;
}

.light-theme-quick-link-styles.secondary-bar a {
  color: #333333;
}

.dark-theme-quick-link-styles.secondary-bar
  .navbar-container.main-menu-content
  ul.navbar-nav
  > li:hover:not(.active)
  > a {
  background: #161d31;
}

.light-theme-quick-link-styles.secondary-bar
  .navbar-container.main-menu-content
  ul.navbar-nav
  > li:hover:not(.active)
  > a {
  background: #f8f8f8;
}

#quick-links-bar ul > li.active > a {
  background: linear-gradient(118deg, #7367f0, rgba(115, 103, 240, 0.7));
  //box-shadow: 0px 0px 6px 1px rgba(115, 103, 240, 0.6);
  border-radius: 4px;
}

.light-theme-quick-link-styles.secondary-bar ul > li.active > a {
  color: white;
}
</style>
