<template>
  <div v-if="name" class="app-item">
    <router-link
        v-if="id && canAccess('app_details')"
        :to="{ name: 'app_details', params: { appId: id } }"
    >
      {{ showId ? `#${id} ${name}` : name }}
    </router-link>
    <span v-else>{{ showId ? `#${id} ${name}` : name }}</span>
  </div>
  <div v-else>
    <router-link
        v-if="id && canAccess('app_details')"
        :to="{ name: 'app_details', params: { appId: id } }"
    >
      {{ id }}
    </router-link>
    <span v-else>{{ id }}</span>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: Number,
      required: true
    },
    name: {
      type: String,
      required: false
    },
    showId: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.app-item {
  white-space: nowrap;
}
</style>
