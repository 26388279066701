var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('multiselect',{staticClass:"my-1",style:({ width: _vm.computedWidth }),attrs:{"track-by":_vm.trackBy,"label":_vm.label,"loading":_vm.isLoading,"options":_vm.sortedOptions,"multiple":true,"close-on-select":false,"clear-on-select":false,"placeholder":_vm.placeholderText,"show-labels":false,"internal-search":_vm.searchUrl === ''},on:{"search-change":function($event){_vm.searchUrl !== '' && _vm.updateSearch($event)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var values = ref.values;
var search = ref.search;
var isOpen = ref.isOpen;
return [(values.length)?_c('span',{directives:[{name:"show",rawName:"v-show",value:(!isOpen),expression:"!isOpen"}],staticClass:"multiselect__single text-nowrap"},[(values.length === 1)?_c('span',[_vm._t("multi-select__one_selection",function(){return [_vm._v(" "+_vm._s(values[0][_vm.label])+" ")]},{"item":values[0]})],2):_c('span',[_vm._v(_vm._s(values.length)+" "+_vm._s(_vm.labelSelectedText))])]):_vm._e()]}},{key:"tag",fn:function(ref){
var option = ref.option;
var remove = ref.remove;
return [_c('span',{staticClass:"custom__tag d-flex"},[_vm._t("multi-select__selected",function(){return [_c('span',[_vm._v(_vm._s(option[_vm.trackBy]))])]},{"option":option}),_c('span',{staticClass:"custom__remove ml-25 cursor-pointer",on:{"click":function($event){$event.stopPropagation();return remove(option)}}},[_vm._v("❌")])],2)]}},{key:"option",fn:function(data){return [_c('div',{staticClass:"option__desc d-flex align-items-center"},[(_vm.localSelectedOptions.includes(data.option))?_c('FeatherIcon',{staticClass:"bg-primary text-white rounded p-25",attrs:{"icon":"CheckIcon","size":"16"}}):_c('FeatherIcon',{attrs:{"icon":"SquareIcon","size":"16"}}),_vm._t("multi-select__text",function(){return [_c('span',{staticClass:"ml-50 option__title"},[_vm._v(_vm._s(data.option))])]},{"option":data.option})],2)]}}],null,true),model:{value:(_vm.localSelectedOptions),callback:function ($$v) {_vm.localSelectedOptions=$$v},expression:"localSelectedOptions"}})}
var staticRenderFns = []

export { render, staticRenderFns }