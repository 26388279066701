<template>
  <div>
    <base-table
        :fields="fields"
        :filterSettings="filterSettings"
        :url="`/api/ip-log/high-risk-metric/user-profiling/${answerId1}/${answerId2}/?type=${type}`"
        :use-query-url="false"
        defaultSortBy="id"
    >
      <template #tableHeader>
        <h4>{{ type | title }}</h4>
      </template>

      <template #cell(app_id)="item">
        <AppElement :id="item.value" :name="item.row.app_name" :showId="true" />
      </template>
      <template #cell(id)="item">
        <UserElement :id="item.value" :status="item.row.status"/>
      </template>
      <template #cell(status)="item">
        <UserStatusIdBadge :status_id="item.value" />
      </template>
    </base-table>
  </div>
</template>

<script>
import BaseTable from '@ps_main/custom-components/BaseTable.vue'
import UserStatusIdBadge from '@/views/users/UserDetails/UserStatusIdBadge.vue'
import AppElement from '@/views/elements/AppElement.vue'

export default {
  components: {
    AppElement,
    UserStatusIdBadge,
    BaseTable
  },
  props: ['answerId1', 'answerId2', 'type'],
  data () {
    return {
      fields: [
        {
          label: 'ANSWER ID 1',
          key: 'answer_id_1',
          sortable: true
        },
        {
          label: 'ANSWER ID 2',
          key: 'answer_id_2',
          sortable: true
        },
        {
          label: 'APP',
          key: 'app_id',
          sortable: false
        },
        {
          label: 'USER ID',
          key: 'id',
          sortable: true
        },
        {
          label: 'STATUS',
          key: 'status',
          sortable: true
        }
      ],
      filterSettings: {}
    }
  }
}
</script>

<style>
</style>
