import Vue from 'vue'
import numeral from 'numeral'

Vue.filter('title', function (value, replacer = '_') {
  if (!value) return ''
  value = value.toString()

  const arr = value.split(replacer)
  const capitalized_array = []
  arr.forEach((word) => {
    const capitalized = word.charAt(0).toUpperCase() + word.slice(1)
    capitalized_array.push(capitalized)
  })
  return capitalized_array.join(' ')
})

Vue.filter('currency', function (value) {
  return `${value} $`
})
Vue.filter('formatCurrency', function (value) {
  return numeral(value).format('0,0.00 $')
})
Vue.filter('newFormatCurrency', function (value) {
  return numeral(value).format('$ 0,0')
})
Vue.filter('formatCount', function (value) {
  return numeral(value).format('0,0')
})
Vue.filter('round2Decimal', function (value) {
  return numeral(value).format('0,0.00')
})
Vue.filter('roundToDecimal', function (value, decimals = 2) {
  if (isNaN(value)) return value
  const formatString = `0,0.${'0'.repeat(decimals)}`
  return numeral(value).format(formatString)
})
Vue.filter('ratingText', function (value) {
  if (
    value === '' ||
        value === null ||
        typeof value === 'undefined'
  ) {
    return ''
  }
  return `${numeral(value).format('0.0')  } ★`
})
Vue.filter('defaultWhenEmpty', function (value, defaultValue) {
  if (
    value === null ||
        value === '' ||
        typeof value === 'undefined'
  ) {
    return defaultValue
  }
  return value
})
Vue.filter('dotDateTimeShort', function (value) {
  const comparedDate = new Date()
  let dateObj = null
  if (typeof value === 'number') {
    if (value < 9999999999) {
      value = value * 1000
    }
    dateObj = new Date(value)
  } else {
    if (
      typeof value !== 'string'
            || value === ''
            || (
              value.length !== 19 &&
                (
                  value.indexOf('T') === -1 ||
                    value.indexOf('Z') === -1
                )
            )
    ) {
      return value
    }
    if (
      value.indexOf('T') > 0 &&
            value.indexOf('Z') > 0
    ) {
      dateObj = new Date(value)
    } else {
      const dateAndTime = value.split(' ')
      dateObj = new Date(`${dateAndTime[0]  }T${  dateAndTime[1]  }.000Z`)
    }
  }

  let dateStr = `${((dateObj.getUTCDate() < 10) ? '0' : '') + dateObj.getUTCDate()  }.${  dateObj.getUTCMonth() < 9 ? '0' : ''  }${dateObj.getUTCMonth() + 1  }.${  dateObj.getUTCFullYear()}`
  const timeStr = [dateObj.getUTCHours(), dateObj.getUTCMinutes()].map(v => (v < 10 ? `0${  v}` : v)).join(':')
  if (Vue.prototype.compare2DateWithTimeZone(comparedDate, dateObj, 'UTC')) {
    dateStr = 'Today'
  } else {
    comparedDate.setUTCDate(comparedDate.getUTCDate() - 1)
    if (Vue.prototype.compare2DateWithTimeZone(comparedDate, dateObj, 'UTC')) {
      dateStr = 'Yesterday'
    }
  }
  return `${dateStr  } ${  timeStr}`
})
Vue.filter('dotDateTime', function (value) {
  const comparedDate = new Date()
  let dateObj = null
  if (typeof value === 'number') {
    if (value < 9999999999) {
      value = value * 1000
    }
    dateObj = new Date(value)
  } else {
    if (
      typeof value !== 'string'
            || value === ''
            || (
              value.length !== 19 &&
                (
                  value.indexOf('T') === -1 ||
                    value.indexOf('Z') === -1
                )
            )
    ) {
      return value
    }
    if (
      value.indexOf('T') > 0 &&
            value.indexOf('Z') > 0
    ) {
      dateObj = new Date(value)
    } else {
      const dateAndTime = value.split(' ')
      dateObj = new Date(`${dateAndTime[0]  }T${  dateAndTime[1]  }.000Z`)
    }
  }

  let dateStr = `${((dateObj.getUTCDate() < 10) ? '0' : '') + dateObj.getUTCDate()  }.${  dateObj.getUTCMonth() < 9 ? '0' : ''  }${dateObj.getUTCMonth() + 1  }.${  dateObj.getUTCFullYear()}`
  const timeStr = [dateObj.getUTCHours(), dateObj.getUTCMinutes(), dateObj.getUTCSeconds()].map(v => (v < 10 ? `0${  v}` : v)).join(':')
  if (Vue.prototype.compare2DateWithTimeZone(comparedDate, dateObj, 'UTC')) {
    dateStr = 'Today'
  } else {
    comparedDate.setUTCDate(comparedDate.getUTCDate() - 1)
    if (Vue.prototype.compare2DateWithTimeZone(comparedDate, dateObj, 'UTC')) {
      dateStr = 'Yesterday'
    }
  }
  return `${dateStr  } ${  timeStr}`
})
Vue.filter('dotDateShort', function (value) {
  if (
    typeof value !== 'string'
        || value === ''
        || (
          value.length !== 19 &&
            (
              value.indexOf('T') === -1 ||
                value.indexOf('Z') === -1
            )
        )
  ) {
    return value
  }
  const comparedDate = new Date()
  let dateObj = null
  if (
    value.indexOf('T') > 0 &&
        value.indexOf('Z') > 0
  ) {
    dateObj = new Date(value)
  } else {
    const dateAndTime = value.split(' ')
    dateObj = new Date(`${dateAndTime[0]  }T${  dateAndTime[1]  }.000Z`)
  }

  let dateStr = `${((dateObj.getUTCDate() < 10) ? '0' : '') + dateObj.getUTCDate()  }.${  dateObj.getUTCMonth() < 9 ? '0' : ''  }${dateObj.getUTCMonth() + 1  }.${  dateObj.getUTCFullYear()}`
  if (Vue.prototype.compare2DateWithTimeZone(comparedDate, dateObj, 'UTC')) {
    dateStr = 'Today'
  } else {
    comparedDate.setUTCDate(comparedDate.getUTCDate() - 1)
    if (Vue.prototype.compare2DateWithTimeZone(comparedDate, dateObj, 'UTC')) {
      dateStr = 'Yesterday'
    }
  }
  return dateStr
})

Vue.filter('getTransactionStatusName', function (value) {
  return window.PRIME_OPINION.getTransactionTypeByValue(value)
})
Vue.filter('toHourMinuteSecond', function (totalSeconds) {
  const isMinus = parseInt(totalSeconds) < 0
  totalSeconds = Math.abs(parseInt(totalSeconds))
  if (isNaN(totalSeconds)) return '-'
  const sec_num = parseInt(totalSeconds, 10)
  const hours = Math.floor(sec_num / 3600)
  const minutes = Math.floor(sec_num / 60) % 60
  const seconds = sec_num % 60
  return `${isMinus ? '-' : ''}
    ${[hours, minutes, seconds]
    .map(v => (v < 10 ? `0${  v}` : v))
    .filter((v, i) => v !== '00' || i > 0)
    .join(':')}`
})

Vue.filter('toHourMinute', function (totalSeconds) {
  totalSeconds = parseInt(totalSeconds)
  if (isNaN(totalSeconds)) return '-'
  const sec_num = parseInt(totalSeconds, 10)
  const hours = Math.floor(sec_num / 3600)
  const minutes = Math.floor(sec_num / 60) % 60
  return [hours, minutes]
    .map(v => (v < 10 ? `0${  v}` : v))
    .filter((v, i) => v !== '00' || i > 0)
    .join(':')
})

Vue.filter('humanizeDate', function (dateString) {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]

  const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ]

  const date = new Date(dateString)
  const dayName = days[date.getDay()]
  const day = date.getDate()
  const monthName = months[date.getMonth()]
  const year = date.getFullYear()

  return `${dayName}, ${day} ${monthName} ${year}`
})

Vue.filter('formatDateRange', function (date) {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  return `${year}-${month}-${day}`
})

Vue.filter('formatDateTimeUTCRange', function (date) {
  const year = date.getUTCFullYear()
  const month = String(date.getUTCMonth() + 1).padStart(2, '0')
  const day = String(date.getUTCDate()).padStart(2, '0')
  const hours = String(date.getUTCHours()).padStart(2, '0')
  const minutes = String(date.getUTCMinutes()).padStart(2, '0')
  const seconds = String(date.getUTCSeconds()).padStart(2, '0')

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
})

Vue.filter('formatPercent2Decimal', function (value) {
  let parseFloatValue = parseFloat(value)
  if (isNaN(parseFloatValue)) return '0.00 %'
  parseFloatValue = parseFloatValue * 100.0
  return `${Vue.filter('round2Decimal')(parseFloatValue)  } %`
})

Vue.filter('formatPercentInt', function (value) {
  let parseFloatValue = parseFloat(value)
  if (isNaN(parseFloatValue)) return '0 %'
  parseFloatValue = parseFloatValue * 100.0
  return `${Math.round(parseFloatValue)  } %`
})

Vue.filter('clickStatusHtml', function (value) {
  const parseIntValue = parseInt(value)
  if (isNaN(parseIntValue)) return value
  switch (parseIntValue) {
  case 1:
    return '<span class="text-success">COMPLETED</span>'
  case 2:
    return '<span class="text-warning">SCREEN OUT</span>'
  case 3:
    return '<span class="text-danger">CANCELLED</span>'
  case 0:
    return '<span class="text-default">INIT</span>'
  default:
    return `UNKNOWN STATUS ${  value}`
  }
})

Vue.filter('formatDatePHP', function (value) {
  if (!value) return null
  let d = value
  if (typeof value === 'string') {
    d = new Date(value)
  }

  let month = `${  d.getMonth() + 1}`,
    day = `${  d.getDate()}`
  const year = d.getFullYear()

  if (month.length < 2) month = `0${  month}`
  if (day.length < 2) day = `0${  day}`

  return [year, month, day].join('-')
})

Vue.filter('questionType', function (questionType) {
  switch (parseInt(questionType)) {
  case 1:
    return 'Single choice'
  case 2:
    return 'Multiple choices'
  case 3:
    return 'Integer'
  case 4:
    return 'Text'
  case 5:
    return 'Long text'
  case 6:
    return 'Other'
  }
  return 'Unknown'
})
Vue.filter('userStatusHtml', function (userStatus) {
  switch (parseInt(userStatus)) {
  case 1:
    return '<span class="user-text-status-1">ACTIVE</span>'
  case 2:
    return '<span class="user-text-status-2">BANNED</span>'
  case 3:
    return '<span class="user-text-status-3">SHADOW BANNED</span>'
  case 4:
    return '<span class="user-text-status-4">REVIEW</span>'
  case 5:
    return '<span class="user-text-status-5">SHADOW REVIEW</span>'
  case 6:
    return '<span class="user-text-status-6">ON HOLD</span>'
  }
  return 'Unknown'
})
Vue.filter('questionUsage', function (questionUsage) {
  const filters = window.CONST_DB_TABLE.QualificationQuestion.Usage.filter((item) => item.id === parseInt(questionUsage))
  if (filters.length > 0) return filters[0].name
  return 'Unknown'
})
Vue.filter('percentage', function (usagePercentage) {
  if (!usagePercentage) return '0 %'
  return `${parseFloat((usagePercentage * 100).toFixed(2))} %`
})

Vue.filter('percentageRound', function (usagePercentage) {
  if (!usagePercentage) return '0 %'
  return `${parseFloat((usagePercentage * 100).toFixed())} %`
})

Vue.filter('percentageRoundDecimal', function (usagePercentage, decimals = 0) {
  if (!usagePercentage) return '0 %'
  const multiplier = Math.pow(10, decimals)
  const percentage = Math.round(usagePercentage * 100 * multiplier) / multiplier
  return `${percentage.toFixed(decimals)} %`
})


Vue.filter('userProfilingSourceText', function (type) {
  switch (type) {
  case 'user_input':
    return 'Manually'
  case 'automatic_match':
    return 'Automatically'
  case 'supplier_match':
    return 'Supplier Match'
  default:
    return 'Unknown'
  }
})
Vue.filter('round2Decimal', function (value) {
  return Math.round((value + Number.EPSILON) * 100) / 100
})
Vue.filter('monthNameFromNumber', function (monthNumber) {
  const date = new Date()
  date.setMonth(monthNumber - 1)

  return date.toLocaleString('en-US', { month: 'long' })
})

Vue.filter('formatMonthYear', function (input) {
  const [year, month] = input.split('-')
  const date = new Date(year, month - 1)
  return date.toLocaleString('default', { month: 'long', year: 'numeric' })
})
