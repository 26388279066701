<template>
    <div class="card base-table-header">
        <b-card-header v-show="!disableHeader">
            <slot name="tableHeader"></slot>
            <ps-filters
                v-if="Object.keys(filterSettings).length > 0"
                :filterSettings="filterSettings"
                :injectActions="injectActions"
                :firstSearch="firstSearch"
                :useQueryUrl="useQueryUrl"
                :isRowFilter="isRowFilter"
                :showImport="showImport"
                :showExport="showExport"
                :searchButtonText="searchButtonText"
                :dynamicFieldButtonAdd="dynamicFieldButtonAdd"
                :hideSearch="hideSearch"
                @search="search"
                @exportToCSV="exportToCSV"
                @changed="params.page = 1"
                @addDynamicField="addDynamicFields"
                @importFile="importFile"
                v-model="filterValues"
                style="margin-bottom: 0; float: right"
                ref="filter"
            >
              <template v-for="key in filterSettingsKeys" v-slot:[`autosuggest-item(${key})`]="{item}">
                <slot :name="`autosuggest-item(${key})`" :item="item"></slot>
              </template>
              <template v-for="key in filterSettingsKeys" v-slot:[`multi-select__selected(${key})`]="{option}">
                <slot :name="`multi-select__selected(${key})`" :option="option"></slot>
              </template>
              <template v-for="key in filterSettingsKeys" v-slot:[`multi-select__text(${key})`]="{option}">
                <slot :name="`multi-select__text(${key})`" :option="option"></slot>
              </template>
              <template v-for="key in filterSettingsKeys" v-slot:[`multi-select__one_selection(${key})`]="{item}">
                <slot :name="`multi-select__one_selection(${key})`" :item="item"></slot>
              </template>
            </ps-filters>
            <slot name="additionalHeader"></slot>
        </b-card-header>
        <div
          :style="computedTableWrapperStyles"
          :class="[
            {'overflow-visible': overflowVisible},
            {'overflow-x-scroll': overflowXScroll},
            {'b-table-sticky-header': stickyHeader}
          ]"
          class="table-responsive mb-0"
        >
            <table
                role="table"
                :aria-busy="isBusy"
                class="table b-table table-striped table-hover"
            >
                <thead role="rowgroup" class="">
                    <tr role="row" class="" v-if="!isMultipleHeader">
                        <template v-for="(oneField, hIndex) in fields">
                            <th
                                v-if="oneField.sortable !== false"
                                :key="hIndex"
                                @click="sortCol(oneField.key)"
                                role="columnheader"
                                :aria-sort="arialSort(oneField.key)"
                                :style="oneField.style"
                                :class="[
                                    rightDividerClass(oneField),
                                    leftDividerClass(oneField),
                                    stickyClass(hIndex, oneField),
                                    oneField.class
                                ]"
                            >
                                {{ oneField.label }}
                            </th>
                            <th v-if="oneField.sortable === false" :style="oneField.style" :key="hIndex">
                                {{ oneField.label }}
                            </th>
                        </template>
                    </tr>
                    <template v-else>
                         <template v-for="(oneRow, hIndex) in fields">
                            <tr role="row" class="border" :key="hIndex">
                                <template v-for="(childField, cIndex) in oneRow">
                                    <th
                                        v-if="childField.sortable !== false && !childField.colspan"
                                        :key="cIndex"
                                        @click="sortCol(childField.key)"
                                        role="columnheader"
                                        :aria-sort="arialSort(childField.key)"
                                        :style="'vertical-align: middle;' + childField.style"
                                        :rowspan="childField.rowspan ? childField.rowspan : 1"
                                        :colspan="childField.colspan ? childField.colspan : 1"
                                        :class="[
                                            rightDividerClass(childField),
                                            leftDividerClass(childField),
                                            stickyClass(cIndex, childField),
                                            childField.class
                                        ]"
                                    >
                                        {{ childField.label }}
                                    </th>
                                    <th v-else
                                        :style="'vertical-align: middle;' + childField.style"
                                        :key="cIndex"
                                        :rowspan="childField.rowspan ? childField.rowspan : 1"
                                        :colspan="childField.colspan ? childField.colspan : 1"
                                        :class="[
                                            rightDividerClass(childField),
                                            leftDividerClass(childField),
                                            stickyClass(cIndex, childField),
                                        ]"
                                    >
                                      <slot :name="'header(' + cIndex + ')'" :field="childField">
                                        {{ childField.label }}
                                      </slot>
                                    </th>
                                </template>
                            </tr>
                        </template>
                    </template>
                </thead>
                <tbody role="rowgroup">
                    <template v-for="(rowTr, indexTr) in items">
                        <slot name="previousTr"></slot>
                        <slot name="trContent" :row="rowTr" :rowIndex="indexTr" :update="updateCell">
                          <tr
                              v-if="rowTr"
                              :key="indexTr"
                              :class="rowTr.class"
                              @click="$emit('selectedRow', rowTr)"
                          >
                            <template v-for="(oneField, tdIndex) in allSingleFields()">
                                <slot :name="'td(' + oneField.key + ')'" :row="rowTr" :field="oneField" :value="rowTr[oneField.key]">
                                    <td
                                        :class="[
                                          rightDividerClass(oneField),
                                          leftDividerClass(oneField),
                                          stickyClass(tdIndex, oneField),
                                          oneField.customFunctionClass ? oneField.customFunctionClass(rowTr[oneField.key], rowTr) : '',
                                          oneField.class || '',
                                        ]"
                                        :style="oneField.contentStyle"
                                        :key="tdIndex"
                                        @click="handleCellClick(oneField, rowTr)"
                                    >
                                        <slot :name="'cell(' + oneField.key + ')'" :row="rowTr" :field="oneField" :rowIndex="indexTr" :update="updateCell" :value="rowTr[oneField.key]">
                                          <template v-if="itemsFormat === 'secondsToHMSReader'">
                                            {{ secondsToHMSReader(rowTr[oneField.key]) }}
                                          </template>
                                          <template v-else>
                                            {{ rowTr[oneField.key] }}
                                          </template>
                                        </slot>
                                    </td>
                                </slot>
                            </template>
                          </tr>
                        </slot>
                        <slot name="nextTr"></slot>
                    </template>
                </tbody>
            </table>
        </div>
        <b-card-footer>
          <div v-if="isLoadingSpinner" class="text-center">
            <b-spinner variant="primary" label="Spinning" class="m-auto"></b-spinner>
          </div>

          <slot name="additionalFooter"></slot>
          <b-row>
                <b-col
                    cols="12"
                    v-show="items && items.length === 0"
                    style="font-weight: 500; text-align: center"
                >
                  No data
                </b-col>
                <b-col cols="12" v-if="showPagination && ((items && items.length > 0) || (paginationData && paginationData.is_simple_paginate))">
                  <pagination-and-table-info
                        :data="paginationData"
                        :useQueryUrl="useQueryUrl"
                        @change="handleChangePage($event)"
                    ></pagination-and-table-info>
                </b-col>
            </b-row>
        </b-card-footer>
    </div>
</template>
<script>
import PaginationAndTableInfo from '@ps_main/custom-components/PaginationAndTableInfo.vue'
import PsFilters from '@ps_main/custom-components/PsFilters.vue'
import { secondsToHMSReader } from '@/utils/helpers'

export default {
  props: {
    fields: { //{ key: 'first_name', label: 'First' },
      type: Array,
      required: true
    },
    itemsFormat: {
      type: String,
      required: '' // secondsToHMSReader
    },
    filterSettings: {
      type: [Array, Object],
      required: false,
      default () {
        return {}
      }
    },
    isRowFilter: {
      type: Boolean,
      default: false
    },
    injectActions: {
      type: Object,
      required: false,
      default () {
        return {}
      }
    },
    itemPerPage: {
      type: Number,
      default: 50
    },
    defaultSortBy: {
      type: String,
      default: 'id'
    },
    defaultSortDirection: {
      type: String,
      default: 'desc'
    },
    method: {
      type: String,
      default: 'get'
    },
    url: {
      type: String,
      required: true
    },
    showSpinner: {
      type: Boolean,
      default: true
    },
    disableHeader: {
      type: Boolean,
      default: false
    },
    overflowVisible: {
      type: Boolean,
      default: false
    },
    overflowXScroll: {
      type: Boolean,
      default: false
    },
    firstSearch: {
      type: Boolean,
      default: true
    },
    hideSearch: {
      type: Boolean,
      default: false
    },
    customKeyDataPagination: {
      type: [String, Boolean],
      default: false
    },
    isFrontendPagination: {
      type: Boolean,
      default: false
    },
    useQueryUrl: {
      type: Boolean,
      default: true
    },
    indexDynamicField: {
      type: [Boolean, Number],
      default: false
    },
    dynamicFields: {
      type: Object,
      required: false
    },
    dynamicFieldButtonAdd: {
      type: String,
      default: 'Add Another'
    },
    isInitOnlyGetFilter: {
      type: Boolean,
      default: false
    },
    showImport: {
      type: Boolean,
      default: false
    },
    searchButtonText: {
      type: String,
      default: 'Search'
    },
    stickyHeader: {
      type: Boolean,
      default: false
    },
    showExport: {
      type: Boolean,
      default: false
    },
    exportUrl: {
      type: String,
      default: ''
    },
    exportName: {
      type: String,
      default: 'export'
    },
    wrapperOverflowY: {
      type: String,
      default: ''
    },
    useSpinnerLoading: {
      type: Boolean,
      default: false
    },
    showPagination: {
      type: Boolean,
      default: true
    }
  },
  components: {
    PaginationAndTableInfo,
    PsFilters
  },
  data () {
    return {
      isBusy: false,
      isLoadingSpinner: false,
      params: {
        page: 1,
        orderBy: this.$route.query.orderBy ?? this.defaultSortBy,
        itemPerPage: this.itemPerPage,
        orderDirection: this.$route.query.orderDirection ?? this.defaultSortDirection,
        initFilter: 0
      },
      items: [],
      responseData: null,
      filterValues: {},
      allItems: {},
      onlyGetFilterHasApplied: false
    }
  },
  methods: {
    secondsToHMSReader,
    showLoadingSpinner () {
      this.isLoadingSpinner = true
    },
    hideLoadingSpinner () {
      this.isLoadingSpinner = false
    },
    emitEditEvent (fieldKey, row) {
      this.$emit('editField', { fieldKey, row })
    },
    handleCellClick (field, row) {
      if (field.editable) {
        this.$emit('editField', { fieldKey: field.key, row })
      }
      this.$emit(`selectedCol${field.key}`, row[field.key])
    },
    stickyClass (index, field) {
      const className = `position-sticky position-left-${index}`
      return { [className]: field.position && field.position === 'sticky' }
    },
    rightDividerClass (field) {
      return { 'right-divider': field.hasRightDivider }
    },
    leftDividerClass (field) {
      return { 'left-divider': field.hasLeftDivider }
    },
    handleChangePage (page) {
      this.params.page = page
      this.sendActionLog(`change pagination ${this.$route.path} with params ${JSON.stringify(this.params)}`)

      if (this.isFrontendPagination) {
        this.items = this.allItems.slice(this.paginationData.from, this.paginationData.to)
      } else {
        this.search()
      }
    },
    setSortField (fieldName) {
      this.params.orderBy = fieldName
    },
    sortCol (fieldName) {
      if (this.params.orderBy === fieldName) {
        this.params.orderDirection = this.params.orderDirection === 'asc' ? 'desc' : 'asc'
      } else {
        this.params.orderBy = fieldName
        this.params.orderDirection = 'desc'
      }

      if (this.useQueryUrl) this.setParamsOrderToUrl()

      if (this.isFrontendPagination) {
        this.sortPaginationFrontend(fieldName)
      } else {
        this.sendActionLog(`sort ${this.$route.path} with params ${JSON.stringify(this.params)}`)
        this.search()
      }
    },
    sortPaginationFrontend (fieldName) {
      if (!this.allItems || !this.allItems.length) {
        this.items = []

        return
      }

      // Helper functions
      const isDate = (value) => !isNaN(Date.parse(value))
      const isNumeric = (value) => typeof value === 'number' || (!isNaN(parseFloat(value)) && isFinite(value))

      const itemsCopy = [...this.allItems]

      const compareValues = (a, b, direction) => {
        const aValue = a[fieldName]
        const bValue = b[fieldName]

        // Numeric comparison
        if (isNumeric(aValue) && isNumeric(bValue)) {
          return direction * (parseFloat(aValue) - parseFloat(bValue))
        }

        // Handle null values (always sort null last)
        if (aValue === null || !a.hasOwnProperty(fieldName)) return 1
        if (bValue === null || !b.hasOwnProperty(fieldName)) return -1

        // Date comparison
        if (isDate(aValue) && isDate(bValue)) {
          return direction * (new Date(aValue) - new Date(bValue))
        }

        // String comparison (case insensitive)
        return direction * aValue.toString().localeCompare(bValue.toString(), undefined, { numeric: true, sensitivity: 'base' })
      }

      // Determine sort direction
      const direction = this.params.orderDirection === 'asc' ? 1 : -1

      // Sort items
      const sortedItems = itemsCopy.sort((a, b) => compareValues(a, b, direction))

      // Paginate sorted items
      this.items = sortedItems.slice(this.paginationData.from, this.paginationData.to)
    },
    arialSort (sortFieldName) {
      return this.params.orderBy === sortFieldName ? this.params.orderDirection === 'asc' ? 'ascending' : 'descending' : 'none'
    },
    setParamsOrderToUrl () {
      const queryParams = new URLSearchParams(window.location.search)
      queryParams.set('orderBy', this.params.orderBy)
      queryParams.set('orderDirection', this.params.orderDirection)
      const newUrl = `${window.location.pathname  }?${  queryParams.toString()}`
      // const humanizeUrl = decodeURIComponent(newUrl);
      window.history.replaceState({}, '', newUrl)
    },
    search (isSilentSearch = false) {
      let hasFileInput = false
      let data = {}
      for (const key in this.filterValues) {
        if (this.filterValues[key] instanceof File) {
          hasFileInput = true
        }
      }

      if (this.useQueryUrl) {
        if (this.$refs.filter) {
          this.$refs.filter.generateFilterValuesByUrl()
        }
        this.generateParamsPaginationByUrl()
        this.generateParamsOrderByUrl()
      }

      if (hasFileInput) {
        this.searchWithFile()
        return
      }

      if (!isSilentSearch) {
        this.isBusy = true
        if (this.showSpinner || this.useSpinnerLoading) this.showLoading()
        if (this.useSpinnerLoading) this.showLoadingSpinner()
      }

      let getParams = {
        ...this.params,
        ...this.filterValues
      }

      if (this.isInitOnlyGetFilter && !this.onlyGetFilterHasApplied) {
        getParams = {...getParams, onlyGetFilter: true}
      }

      if (this.indexDynamicField) {
        const additionalParam = {'dynamicFieldCount': (this.filterSettings[this.indexDynamicField].length - 1)}

        getParams = {
          ...getParams,
          ...additionalParam
        }
      }

      if (typeof this.injectActions.modifyParams === 'function') {
        getParams = this.injectActions.modifyParams(getParams)
      }

      if (this.method === 'get') {
        data = {params: getParams}
      } else {
        data = getParams
      }

      this.$http[this.method](this.url, data)
        .then((response) => {
          this.handleResponse(response)
        })
        .catch((errors) => {
          this.handleErrors(errors)
        })
    },
    searchWithFile () {
      this.isBusy = true
      if (this.showSpinner || this.useSpinnerLoading) this.showLoading()
      if (this.useSpinnerLoading) this.showLoadingSpinner()

      const formData = new FormData()

      for (const key in this.filterValues) {
        if (this.filterValues[key] instanceof File) {
          formData.append(key, this.filterValues[key])
        }
      }

      // Merge params and filterValues
      const mergedParams = {
        ...this.params,
        ...this.filterValues
      }

      // Append other parameters to formData
      for (const key in mergedParams) {
        formData.append(key, mergedParams[key])
      }

      this.$http.post(`${this.url}/file`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(response => {
          this.handleResponse(response)
        })
        .catch(errors => {
          this.handleErrors(errors)
        })
    },
    importFile () {
      this.isBusy = true
      if (this.showSpinner || this.useSpinnerLoading) this.showLoading()
      if (this.useSpinnerLoading) this.showLoadingSpinner()

      const formData = new FormData()

      for (const key in this.filterValues) {
        if (this.filterValues[key] instanceof File) {
          formData.append(key, this.filterValues[key])
        }
      }

      this.$http
        .post(`${ this.url  }/import`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((response) => {
          if (this.showSpinner || this.useSpinnerLoading) this.hideLoading()
          if (this.useSpinnerLoading) this.hideLoadingSpinner()

          this.$emit('beforeShowingData', {filterValues: this.filterValues})
          this.filterValues.file = null
          this.search()

          if (response?.data?.length) {
            this.alertSuccess(`${response.data.length} data has been import`)
          } else {
            this.alertSuccess('Data has been import')
          }

          this.isBusy = false
        })
        .catch((errors) => {
          this.handleErrors(errors)
        })
    },
    handleResponse (response) {
      if (this.showSpinner || this.useSpinnerLoading) this.hideLoading()
      if (this.useSpinnerLoading) this.hideLoadingSpinner()

      this.onlyGetFilterHasApplied = true
      this.$emit('beforeShowingData', {filterValues: this.filterValues})
      const responseData = response.data

      if (this.isFrontendPagination) {
        // Store all data from the backend
        if (typeof responseData.data === 'object' && !Array.isArray(responseData.data) && responseData.data !== null) {
          this.allItems = Object.values(responseData.data)
        } else {
          this.allItems = responseData.data
        }

        this.sortPaginationFrontend(this.params.orderBy)
      } else if (typeof responseData.data === 'object' && !Array.isArray(responseData.data) && responseData.data !== null) {
        this.items = Object.values(responseData.data)
      } else {
        this.items = responseData.data
      }

      this.responseData = responseData
      this.initiateFilters(responseData)
      this.$emit('updateStatistic', responseData.statistic_data)
      this.$emit('afterShowingData', responseData)
      this.isBusy = false
    },
    handleErrors (errors) {
      if (typeof errors.response === 'object') {
        if (typeof errors.response.data.errors === 'object') {
          this.alertError(errors.response.data.errors)
        } else {
          this.alertError(errors.response.data)
        }
      } else {
        this.alertError(errors.message)
      }

      if (this.showSpinner || this.useSpinnerLoading) this.hideLoading()
      if (this.useSpinnerLoading) this.hideLoadingSpinner()
      this.isBusy = false
    },
    initiateFilters (responseData) {
      if (this.params.initFilter === 0 && responseData.filterData) {
        const filterSettings = Array.isArray(this.filterSettings) ? this.filterSettings : [this.filterSettings]

        for (const property in responseData.filterData) {
          filterSettings.forEach(filterSetting => {
            if (filterSetting[property] && typeof filterSetting[property].defaultData !== 'undefined') {
              filterSetting[property].data = [
                filterSetting[property].defaultData,
                ...responseData.filterData[property]
              ]
            } else if (filterSetting[property]) {
              filterSetting[property].data = responseData.filterData[property]
            }

            const { defaultValue = null } = filterSetting[property] || {}
            if (Array.isArray(defaultValue) && defaultValue.includes('all')) {
              filterSetting[property].defaultValue = responseData.filterData[property].map(item => item.value)
            }
          })
        }

        this.params.initFilter = 1

        if (this.$refs.filter && !responseData.filterData.isAdditionalFilter) {
          this.$refs.filter.generateModels()
        }
      }
    },
    generateParamsPaginationByUrl () {
      const queryParams = new URLSearchParams(window.location.search)

      const page = queryParams.get('page')
      if (page) {
        this.params.page = page
      }
    },
    generateParamsOrderByUrl () {
      const queryParams = new URLSearchParams(window.location.search)

      const sortByQueryParam = queryParams.get('sortBy')
      if (sortByQueryParam) {
        this.params.sortBy = sortByQueryParam
      }

      const sortDirectionQueryParam = queryParams.get('sortDirection')
      if (sortDirectionQueryParam) {
        this.params.sortDirection = sortDirectionQueryParam
      }
    },
    updateCell (cellData) {
      this.items[cellData.rowIndex][cellData.field.key] = cellData.value
    },
    allSingleFields () {
      if (this.fields[0] instanceof Array) {
        const singleFields = []
        for (const oneRow of this.fields) {
          for (const oneField of oneRow) {
            if (!oneField.colspan) {
              singleFields.push(oneField)
            }
          }
        }
        return singleFields
      } else {
        return this.fields
      }
    },
    addDynamicFields () {
      const newFields = Object.entries(this.dynamicFields).map(([key, value]) => {
        const newNum = this.filterSettings[this.indexDynamicField].length
        const newKeyName = key.replace(/\d+/, newNum)
        const items = Object.assign({}, value)

        items.defaultValue = ''
        items.data = []

        if (value.customGenerateSuggestion) {
          items.customGenerateSuggestion = value.customGenerateSuggestion
        }

        if (value.change) {
          items.change = value.change
        }

        return [newKeyName, items]
      })

      this.filterSettings[this.indexDynamicField] = [
        ...this.filterSettings[this.indexDynamicField],
        Object.fromEntries(newFields)
      ]

      this.$forceUpdate()
    },
    isEditable (field) {
      return field.editable
    },
    async exportToCSV () {
      this.isBusy = true
      if (this.showSpinner || this.useSpinnerLoading) this.showLoading()
      if (this.useSpinnerLoading) this.showLoadingSpinner()

      // Check if the method is 'post', and structure the request accordingly
      // eslint-disable-next-line multiline-ternary
      const requestData = this.method === 'post'
      // eslint-disable-next-line multiline-ternary
        ? {
          // POST request sends the data directly (without 'body' or 'params' keys)
          ...this.params,
          ...this.filterValues,
          only_file_name: true
        }
        : {
          // GET request sends the data as URL parameters
          params: {
            ...this.params,
            ...this.filterValues,
            only_file_name: true
          }
        }

      if (this.exportUrl) {
        const response = await this.$http[this.method](this.exportUrl, {
          ...this.filterValues,
          responseType: 'blob' // Ensure the response is treated as a blob (binary data)
        })

        if (this.showSpinner || this.useSpinnerLoading) this.hideLoading()
        if (this.useSpinnerLoading) this.hideLoadingSpinner()

        // Create a new Blob object using the response data
        const blob = new Blob([response.data], { type: 'text/csv' })

        // Create a URL for the blob object
        const downloadUrl = window.URL.createObjectURL(blob)

        // Create a temporary <a> element to trigger the download
        const link = document.createElement('a')
        link.href = downloadUrl
        link.setAttribute('download', `${this.exportName}.csv`) // Set the file name

        // Append the link to the document and trigger the click event
        document.body.appendChild(link)
        link.click()

        // Clean up the URL and remove the link element
        link.remove()
        window.URL.revokeObjectURL(downloadUrl)

        this.isBusy = false
      } else {
        this.$http[this.method](`${this.url}/export`, requestData)
          .then((response) => {
            const filename = response.data

            // For the second request to fetch the file
            // eslint-disable-next-line multiline-ternary
            const secondRequestData = this.method === 'post'
            // eslint-disable-next-line multiline-ternary
              ? {
                // POST request sends the data directly
                ...this.params,
                ...this.filterValues,
                dynamicFieldCount: (this.filterSettings[this.indexDynamicField].length - 1)
              }
              : {
                // GET request sends the data as URL parameters
                params: {
                  ...this.params,
                  ...this.filterValues,
                  dynamicFieldCount: (this.filterSettings[this.indexDynamicField].length - 1)
                }
              }

            this.$http[this.method](`${this.url}/export`, {
              ...secondRequestData,
              responseType: 'blob' // Expecting a file blob
            })
              .then((response) => {
                if (this.showSpinner || this.useSpinnerLoading) this.hideLoading()
                if (this.useSpinnerLoading) this.hideLoadingSpinner()

                // Create a downloadable link for the exported file
                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', filename) // Filename from the first response
                document.body.appendChild(link)
                link.click() // Trigger download

                this.isBusy = false
              })
              .catch((errors) => {
                this.handleExportErrors(errors)
              })
          })
          .catch((errors) => {
            this.handleExportErrors(errors)
          })
      }
    },
    handleExportErrors (errors) {
      if (typeof errors.response === 'object') {
        if (typeof errors.response.data.errors === 'object') {
          this.alertError(errors.response.data.errors)
        } else {
          this.alertError(errors.response.data)
        }
      } else {
        this.alertError(errors.message)
      }

      if (this.showSpinner || this.useSpinnerLoading) this.hideLoading()
      if (this.useSpinnerLoading) this.hideLoadingSpinner()
      this.isBusy = false
    }
  },
  computed: {
    paginationData () {
      if (this.isFrontendPagination) {
        const paginationFronted = {
          all_items: this.allItems
        }

        if (!this.allItems) {
          return this.laravelPaginationPages(paginationFronted)
        }

        // Calculate total number of items
        paginationFronted.total = this.allItems.length

        // Calculate total number of pages
        paginationFronted.last_page = Math.ceil(paginationFronted.total / this.params.itemPerPage)

        // Apply custom pagination
        paginationFronted.from = (this.params.page - 1) * this.params.itemPerPage
        paginationFronted.to = paginationFronted.from + this.params.itemPerPage
        paginationFronted.current_page = this.params.page

        return this.laravelPaginationPages(paginationFronted)
      }

      if (this.customKeyDataPagination && this.responseData) {
        return this.laravelPaginationPages(this.responseData[this.customKeyDataPagination])
      }

      return this.laravelPaginationPages(this.responseData)
    },
    computedTableWrapperStyles () {
      const styles = {}
      if (this.stickyHeader) {
        styles.maxHeight = '80vh'
        styles.overflowY = 'auto'
      }
      if (this.wrapperOverflowY) {
        styles.overflowY = this.wrapperOverflowY
      }
      return styles
    },
    isMultipleHeader () {
      return this.fields[0] instanceof Array
    },
    filterSettingsKeys () {
      const keys = []

      const filterSettingsArray = Array.isArray(this.filterSettings) ? this.filterSettings : [this.filterSettings]

      filterSettingsArray.forEach(filterSetting => {
        for (const key in filterSetting) {
          keys.push(key)
        }
      })

      return keys
    }
  },
  created () {
    for (const property in this.filterSettings) {
      if (
        this.filterSettings[property].data instanceof Array &&
                typeof this.filterSettings[property].defaultData !== 'undefined'
      ) {
        this.filterSettings[property].data.push(
          this.filterSettings[property].defaultData
        )
      }
    }
    if (Object.keys(this.filterSettings).length === 0) this.search()
  }
}
</script>

<style lang="scss">

[dir] .table-striped tbody tr:not(:hover):nth-of-type(even) .position-sticky:not(:hover) {
  background-color: white;
  z-index: 2;
}

[dir] .table-striped tbody tr:not(:hover):nth-of-type(odd) .position-sticky:not(:hover) {
  background-color: #fafafc;
  z-index: 2;
}

[dir] .table-striped tbody tr:hover .position-sticky {
  background: inherit;
}

.table-column-sticky {
  .table-responsive {
    overflow-y: hidden;

    table {
      overflow-y: hidden;
    }
  }

  td {
    position: relative;
  }

  tr:not(td.position-sticky):hover {
    background-color: rgb(226 226 224 / 50%) !important;

    td:not(td.position-sticky) {
      z-index: 1;
    }

    td.position-sticky {
      background-color: rgb(214 214 213 / 100%) !important;
      z-index: 2;
      width: 100%;
    }
  }

  td:not(.position-sticky):hover {
    &:not(.is-gradient-color)::after {
      background-color: rgb(226 226 224 / 30%);
      content: '\00a0';
      height: 200vh;
      left: 0;
      position: absolute;
      top: -100vh;
      width: 100%;
      pointer-events: none;
    }
  }
}

/* Dark Mode Styles */
.dark-layout .table-column-sticky tbody tr:not(:hover):nth-of-type(even) .position-sticky:not(:hover) {
  background-color: rgb(36, 43, 61);
  z-index: 2;
}

.dark-layout .table-column-sticky tbody tr:not(:hover):nth-of-type(odd) .position-sticky:not(:hover) {
  background-color: #242b3d;
  z-index: 2;
}

.dark-layout .table-column-sticky tr:not(td.position-sticky):hover {
  background-color: rgba(0, 0, 0, 0.5) !important;

  td:not(td.position-sticky) {
    z-index: 1;
  }

  td.position-sticky {
    background-color: rgba(0, 0, 0, 1) !important;
    z-index: 2;
    width: 100%;
  }
}

.dark-layout .table-column-sticky td:not(.position-sticky):hover:not(.is-gradient-color)::after {
  background-color: rgba(0, 0, 0, 0.33);
  content: '\00a0';
  height: 200vh;
  left: 0;
  position: absolute;
  top: -100vh;
  width: 100%;
  pointer-events: none;
}

.base-table-header .card-header {
    padding-bottom: 0.5rem;
}
thead tr.border th {
    border: 1px solid #dae1e7 !important;
}

.right-divider {
  box-shadow: inset -3px 0 1px -1px #000;
}
.left-divider {
  box-shadow: inset 3px 0 1px -1px #000;
}

</style>
