//Sub permission: check \App\Lib\AdminPermissions.php
import routerConfigs from '../router-configs/baseRouterConfigs'

export default [
  /* Monitoring Menu Begin */
  {
    title: 'Monitoring',
    icon: 'MonitorIcon',
    children: [
      {
        url: routerConfigs.AB_TESTS.path,
        title: 'A/B Tests',
        icon: 'ThermometerIcon',
        route: routerConfigs.AB_TESTS.name,
        sub_permissions: [
          {
            id: routerConfigs.AB_TESTS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.ADMIN_DASHBOARD.path,
        title: 'New Dashboard',
        icon: 'HomeIcon',
        route: routerConfigs.ADMIN_DASHBOARD.name,
        sub_permissions: [
          {
            id: routerConfigs.ADMIN_DASHBOARD.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.DASHBOARD.path,
        title: 'Dashboard',
        icon: 'HomeIcon',
        route: routerConfigs.DASHBOARD.name,
        sub_permissions: [
          {
            id: routerConfigs.DASHBOARD.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        title: 'Chat',
        icon: 'MessageSquareIcon',
        children: [
          {
            url: routerConfigs.CHAT_DASHBOARD.path,
            title: 'Chat Dashboard',
            icon: 'MessageSquareIcon',
            route: routerConfigs.CHAT_DASHBOARD.name,
            sub_permissions: [
              {
                id: routerConfigs.CHAT_DASHBOARD.name,
                text: 'Main',
                main: true
              }
            ]
          },
          {
            url: routerConfigs.CHAT_SERVICE_OVERVIEW.path,
            title: 'Chat Service Overview',
            icon: 'TargetIcon',
            route: routerConfigs.CHAT_SERVICE_OVERVIEW.name,
            sub_permissions: [
              {
                id: routerConfigs.CHAT_SERVICE_OVERVIEW.name,
                text: 'Main',
                main: true
              }
            ]
          },
          {
            url: routerConfigs.CHAT_WEEKLY_CUSTOMER_SATISFACTION.path,
            title: 'Chat Weekly CSat',
            icon: 'SmileIcon',
            route:
              routerConfigs.CHAT_WEEKLY_CUSTOMER_SATISFACTION.name,
            sub_permissions: [
              {
                id: routerConfigs.CHAT_WEEKLY_CUSTOMER_SATISFACTION
                  .name,
                text: 'Main',
                main: true
              }
            ]
          },
          {
            url: routerConfigs.CHAT_WEEKLY_PRODUCTIVITY.path,
            title: 'Chat Weekly Productivity',
            icon: 'TrendingUpIcon',
            route: routerConfigs.CHAT_WEEKLY_PRODUCTIVITY.name,
            sub_permissions: [
              {
                id: routerConfigs.CHAT_WEEKLY_PRODUCTIVITY.name,
                text: 'Main',
                main: true
              }
            ]
          },
          {
            url: routerConfigs.CHAT_FIRST_RESPONSE_TIME.path,
            title: 'Chat First Response Time',
            icon: 'ClockIcon',
            route: routerConfigs.CHAT_FIRST_RESPONSE_TIME.name,
            sub_permissions: [
              {
                id: routerConfigs.CHAT_FIRST_RESPONSE_TIME.name,
                text: 'Main',
                main: true
              }
            ]
          }
        ]
      },
      {
        url: routerConfigs.ADMIN_ALERT_SYSTEM.path,
        title: 'Alert System',
        icon: 'AlertOctagonIcon',
        app: ['bitburst'],
        route: routerConfigs.ADMIN_ALERT_SYSTEM.name,
        sub_permissions: [
          {
            id: routerConfigs.ADMIN_ALERT_SYSTEM.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.CLICK_TICKET_PRODUCTIVITY.path,
        title: 'Click Ticket Productivity',
        icon: 'MicIcon',
        route: routerConfigs.CLICK_TICKET_PRODUCTIVITY.name,
        always_available_for_ids: [1, 26],
        sub_permissions: [
          {
            id: routerConfigs.CLICK_TICKET_PRODUCTIVITY.name,
            text: 'Main',
            main: true,
            always_available_for_ids: [1, 26]
          }
        ]
      },
      {
        url: routerConfigs.DAILY_OVERVIEW.path,
        title: 'Daily Overview',
        icon: 'BarChart2Icon',
        route: routerConfigs.DAILY_OVERVIEW.name,
        sub_permissions: [
          {
            id: routerConfigs.DAILY_OVERVIEW.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.DAILY_SURVEY_SYNC.path,
        title: 'Daily Survey Sync',
        icon: 'RefreshCcwIcon',
        route: routerConfigs.DAILY_SURVEY_SYNC.name,
        sub_permissions: [
          {
            id: routerConfigs.DAILY_SURVEY_SYNC.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        title: 'Top Surveys',
        icon: 'PocketIcon',
        children: [
          {
            url: routerConfigs.DYNATA_TOP_SURVEYS.path,
            title: 'Dynata Top Surveys',
            icon: 'PocketIcon',
            route: routerConfigs.DYNATA_TOP_SURVEYS.name,
            sub_permissions: [
              {
                id: routerConfigs.DYNATA_TOP_SURVEYS.name,
                text: 'Main',
                main: true
              }
            ]
          },
          {
            url: routerConfigs.MISSED_OPPORTUNITIES.path,
            title: 'Missed Opportunities',
            icon: 'PhoneMissedIcon',
            route: routerConfigs.MISSED_OPPORTUNITIES.name,
            sub_permissions: [
              {
                id: routerConfigs.MISSED_OPPORTUNITIES.name,
                text: 'Main',
                main: true
              }
            ]
          },
          {
            url: routerConfigs.SUPPLIER_TOP_SURVEYS.path,
            title: 'Supplier Top Surveys',
            icon: 'StarIcon',
            route: routerConfigs.SUPPLIER_TOP_SURVEYS.name,
            sub_permissions: [
              {
                id: routerConfigs.SUPPLIER_TOP_SURVEYS.name,
                text: 'Main',
                main: true
              }
            ]
          },
          {
            url: routerConfigs.TOP_SURVEYS.path,
            title: 'Top Surveys',
            icon: 'AwardIcon',
            route: routerConfigs.TOP_SURVEYS.name,
            sub_permissions: [
              {
                id: routerConfigs.TOP_SURVEYS.name,
                text: 'Main',
                main: true
              }
            ]
          }
        ]
      },
      {
        url: routerConfigs.JOB_LOGS.path,
        title: 'Job Logs',
        icon: 'FramerIcon',
        route: routerConfigs.JOB_LOGS.name,
        sub_permissions: [
          {
            id: routerConfigs.JOB_LOGS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        title: 'Report',
        icon: 'FolderIcon',
        children: [
          {
            url: routerConfigs.MASTER_REPORT.path,
            title: 'Master Report',
            icon: 'BookOpenIcon',
            route: routerConfigs.MASTER_REPORT.name,
            sub_permissions: [
              {
                id: routerConfigs.MASTER_REPORT.name,
                text: 'Main',
                main: true
              }
            ]
          },
          {
            url: routerConfigs.PERFORMANCE_REPORT.path,
            title: 'Clicks Report',
            icon: 'MousePointerIcon',
            route: routerConfigs.PERFORMANCE_REPORT.name,
            sub_permissions: [
              {
                id: routerConfigs.PERFORMANCE_REPORT.name,
                text: 'Main',
                main: true
              }
            ]
          },
          {
            url: routerConfigs.COMPLETES_REPORT.path,
            title: 'Completes Report',
            icon: 'CheckSquareIcon',
            route: routerConfigs.COMPLETES_REPORT.name,
            sub_permissions: [
              {
                id: routerConfigs.COMPLETES_REPORT.name,
                text: 'Main',
                main: true
              }
            ]
          },
          {
            url: routerConfigs.FINANCIAL_REPORT.path,
            title: 'Financial Report',
            icon: 'DollarSignIcon',
            route: routerConfigs.FINANCIAL_REPORT.name,
            sub_permissions: [
              {
                id: routerConfigs.FINANCIAL_REPORT.name,
                text: 'Main',
                main: true
              }
            ],
            always_available_for_ids: [1]
          },
          {
            url: routerConfigs.CLAIMS_OVERVIEW.path,
            title: 'Claims Report',
            icon: 'PocketIcon',
            route: routerConfigs.CLAIMS_OVERVIEW.name,
            sub_permissions: [
              {
                id: routerConfigs.CLAIMS_OVERVIEW.name,
                text: 'Main',
                main: true
              }
            ]
          },
          {
            url: routerConfigs.VARIOUS_REPORT.path,
            title: 'Various Report',
            icon: 'LayersIcon',
            route: routerConfigs.VARIOUS_REPORT.name,
            sub_permissions: [
              {
                id: routerConfigs.VARIOUS_REPORT.name,
                text: 'Main',
                main: true
              }
            ]
          },
          {
            url: routerConfigs.COHORT_REPORT.path,
            title: 'Cohort Report',
            icon: 'LayoutIcon',
            route: routerConfigs.COHORT_REPORT.name,
            sub_permissions: [
              {
                id: routerConfigs.COHORT_REPORT.name,
                text: 'Main',
                main: true
              }
            ]
          }
        ]
      },
      {
        title: 'Statistics',
        icon: 'TrendingUpIcon',
        children: [
          {
            url: routerConfigs.SUPPLIER_STATISTICS.path,
            title: 'Supplier Statistics',
            icon: 'TrendingUpIcon',
            route: routerConfigs.SUPPLIER_STATISTICS.name,
            sub_permissions: [
              {
                id: routerConfigs.SUPPLIER_STATISTICS.name,
                text: 'Main',
                main: true
              }
            ]
          },
          {
            url: routerConfigs.SURVEY_LIST_RELOAD_STATISTICS.path,
            title: 'Survey List Reload Statistics',
            icon: 'RotateCwIcon',
            route: routerConfigs.SURVEY_LIST_RELOAD_STATISTICS.name,
            sub_permissions: [
              {
                id: routerConfigs.SURVEY_LIST_RELOAD_STATISTICS.name,
                text: 'Main',
                main: true
              }
            ]
          },
          {
            url: routerConfigs.RESPONSE_STATUS_STATISTICS.path,
            title: 'Response Status Statistics',
            icon: 'TwitchIcon',
            route: routerConfigs.RESPONSE_STATUS_STATISTICS.name,
            sub_permissions: [
              {
                id: routerConfigs.RESPONSE_STATUS_STATISTICS.name,
                text: 'Main',
                main: true
              }
            ]
          },
          {
            url: routerConfigs.USER_BAN_STATISTICS.path,
            title: 'User Ban Statistics',
            icon: 'SlashIcon',
            route: routerConfigs.USER_BAN_STATISTICS.name,
            sub_permissions: [
              {
                id: routerConfigs.USER_BAN_STATISTICS.name,
                text: 'Main',
                main: true
              }
            ]
          },
          {
            url: routerConfigs.DELIVERY_OVERVIEW.path,
            title: 'Delivery Overview',
            icon: 'BarChart2Icon',
            route: routerConfigs.DELIVERY_OVERVIEW.name,
            sub_permissions: [
              {
                id: routerConfigs.DELIVERY_OVERVIEW.name,
                text: 'Main',
                main: true
              }
            ]
          }
        ]
      },
      {
        url: routerConfigs.SUPPORT_PERFORMANCE.path,
        title: 'Support Performance',
        icon: 'HeadphonesIcon',
        route: routerConfigs.SUPPORT_PERFORMANCE.name,
        sub_permissions: [
          {
            id: routerConfigs.SUPPORT_PERFORMANCE.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        title: 'Reconciliations',
        icon: 'TrendingDownIcon',
        children: [
          {
            url: routerConfigs.RECONCILIATION_CHART.path,
            title: 'Reconciliation Chart',
            icon: 'TrendingDownIcon',
            route: routerConfigs.RECONCILIATION_CHART.name,
            sub_permissions: [
              {
                id: routerConfigs.RECONCILIATION_CHART.name,
                text: 'Main',
                main: true
              }
            ]
          },
          {
            url: routerConfigs.RECONCILIATION_PACING.path,
            title: 'Reconciliation Pacing',
            icon: 'BarChart2Icon',
            route: routerConfigs.RECONCILIATION_PACING.name,
            sub_permissions: [
              {
                id: routerConfigs.RECONCILIATION_PACING.name,
                text: 'Main',
                main: true
              }
            ]
          },
          {
            url: routerConfigs.RECONCILIATION_REPORT.path,
            title: 'Reconciliation Report',
            icon: 'TrendingDownIcon',
            route: routerConfigs.RECONCILIATION_REPORT.name,
            sub_permissions: [
              {
                id: routerConfigs.RECONCILIATION_REPORT.name,
                text: 'Main',
                main: true
              }
            ]
          }
        ]
      },
      {
        url: routerConfigs.QUEUE_ALERT_SYSTEM.path,
        title: 'Queue Alert System',
        icon: 'LayersIcon',
        route: routerConfigs.QUEUE_ALERT_SYSTEM.name,
        sub_permissions: [
          {
            id: routerConfigs.QUEUE_ALERT_SYSTEM.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.ACTIVITY_BREAKDOWN.path,
        title: 'Activity Breakdown',
        icon: 'DivideCircleIcon',
        route: routerConfigs.ACTIVITY_BREAKDOWN.name,
        sub_permissions: [
          {
            id: routerConfigs.ACTIVITY_BREAKDOWN.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.JOB_MONITORING.path,
        title: 'Job Monitoring',
        icon: 'BoxIcon',
        route: routerConfigs.JOB_MONITORING.name,
        sub_permissions: [
          {
            id: routerConfigs.JOB_MONITORING.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.SUPPLIER_STATEMENT.path,
        title: 'Supplier Statement',
        icon: 'ApertureIcon',
        route: routerConfigs.SUPPLIER_STATEMENT.name,
        sub_permissions: [
          {
            id: routerConfigs.SUPPLIER_STATEMENT.name,
            text: 'Main',
            main: true
          }
        ]
      }
    ]
  },
  /* Monitoring Menu End */

  /* Surveys Menu Begin */
  {
    title: 'Surveys',
    icon: 'FileTextIcon',
    children: [
      {
        url: routerConfigs.CINT_DELIVERY_SHARE.path,
        title: 'Cint Delivery Share',
        icon: 'Share2Icon',
        route: routerConfigs.CINT_DELIVERY_SHARE.name,
        sub_permissions: [
          {
            id: routerConfigs.CINT_DELIVERY_SHARE.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.CINT_IMPORTS.path,
        title: 'Cint Imports',
        icon: 'UploadCloudIcon',
        route: routerConfigs.CINT_IMPORTS.name,
        sub_permissions: [
          {
            id: routerConfigs.CINT_IMPORTS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.SUPPLIERS.path,
        title: 'Suppliers',
        icon: 'DownloadIcon',
        route: routerConfigs.SUPPLIERS.name,
        sub_permissions: [
          {
            id: routerConfigs.SUPPLIERS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        title: 'Supplier detail',
        hidden: true,
        sub_permissions: [
          {
            id: routerConfigs.SUPPLIER_DETAILS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.SUPPLIER_BUYERS.path,
        title: 'Supplier Buyers',
        icon: 'UploadIcon',
        route: routerConfigs.SUPPLIER_BUYERS.name,
        sub_permissions: [
          {
            id: routerConfigs.SUPPLIER_BUYERS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        title: 'Supplier Buyer detail',
        hidden: true,
        sub_permissions: [
          {
            id: routerConfigs.SUPPLIER_BUYER_DETAILS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.SURVEYS.path,
        title: 'Surveys',
        icon: 'LayoutIcon',
        route: routerConfigs.SURVEYS.name,
        sub_permissions: [
          {
            id: routerConfigs.SURVEYS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        title: 'Survey detail',
        hidden: true,
        sub_permissions: [
          {
            id: routerConfigs.SURVEY_DETAILS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.SURVEY_GROUPS.path,
        title: 'Survey Groups',
        icon: 'LayersIcon',
        route: routerConfigs.SURVEY_GROUPS.name,
        sub_permissions: [
          {
            id: routerConfigs.SURVEY_GROUPS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.SURVEY_INVITATIONS.path,
        title: 'Survey Invitations',
        icon: 'InboxIcon',
        route: routerConfigs.SURVEY_INVITATIONS.name,
        sub_permissions: [
          {
            id: routerConfigs.SURVEY_INVITATIONS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.SURVEY_INVENTORY.path,
        title: 'Survey Inventory',
        icon: 'BookIcon',
        route: routerConfigs.SURVEY_INVENTORY.name,
        sub_permissions: [
          {
            id: routerConfigs.SURVEY_INVENTORY.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.QUESTIONS.path,
        title: 'Questions',
        icon: 'HelpCircleIcon',
        route: routerConfigs.QUESTIONS.name,
        sub_permissions: [
          {
            id: routerConfigs.QUESTIONS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        title: 'Question details',
        hidden: true,
        sub_permissions: [
          {
            id: routerConfigs.QUESTION_DETAILS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.QUESTIONS_MAPPING.path,
        title: 'Questions Mapping',
        icon: 'BookmarkIcon',
        route: routerConfigs.QUESTIONS_MAPPING.name,
        sub_permissions: [
          {
            id: routerConfigs.QUESTIONS_MAPPING.name,
            text: 'Main',
            main: true
          },
          {
            id: routerConfigs.QUESTIONS_MAPPING_STORE.name,
            text: 'Question Mapping Add'
          },
          {
            id: routerConfigs.QUESTIONS_MAPPING_COPY.name,
            text: 'Question Mapping Copy'
          }
        ]
      },
      {
        title: 'Question Mapping Details',
        hidden: true,
        sub_permissions: [
          {
            id: routerConfigs.QUESTIONS_MAPPING_DETAILS.name,
            text: 'Main',
            main: true
          }
        ]
      }
    ]
  },
  /* Surveys Menu End */

  /* Publishers Menu Begin */
  {
    title: 'Publishers',
    icon: 'RadioIcon',
    children: [
      {
        url: routerConfigs.APPS.path,
        title: 'Apps',
        icon: 'BoxIcon',
        route: routerConfigs.APPS.name,
        sub_permissions: [
          {
            id: routerConfigs.APPS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        title: 'Apps details',
        hidden: true,
        sub_permissions: [
          {
            id: routerConfigs.APP_DETAILS.name,
            text: 'Main',
            main: true,
            always_available_for_ids: [1]
          }
        ],
        always_available_for_ids: [1]
      },
      {
        url: routerConfigs.APP_SUPPLIER_SETTINGS.path,
        title: 'App Supplier Settings',
        icon: 'CpuIcon',
        route: routerConfigs.APP_SUPPLIER_SETTINGS.name,
        sub_permissions: [
          {
            id: routerConfigs.APP_SUPPLIER_SETTINGS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.SURVEY_PUBLISHERS.path,
        title: 'Publishers',
        icon: 'UserIcon',
        route: routerConfigs.SURVEY_PUBLISHERS.name,
        sub_permissions: [
          {
            id: routerConfigs.SURVEY_PUBLISHERS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        title: 'Publisher details',
        hidden: true,
        sub_permissions: [
          {
            id: routerConfigs.PUBLISHER_DETAILS.name,
            text: 'Main',
            main: true,
            always_available_for_ids: [1]
          }
        ],
        always_available_for_ids: [1]
      },
      {
        url: routerConfigs.PUBLISHER_PAYMENTS.path,
        title: 'Publisher Payments',
        icon: 'CreditCardIcon',
        route: routerConfigs.PUBLISHER_PAYMENTS.name,
        sub_permissions: [
          {
            id: routerConfigs.PUBLISHER_PAYMENTS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.APP_OVERVIEW.path,
        title: 'App Overview',
        icon: 'GridIcon',
        route: routerConfigs.APP_OVERVIEW.name,
        sub_permissions: [
          {
            id: routerConfigs.APP_OVERVIEW.name,
            text: 'Main',
            main: true
          }
        ]
      }
    ]
  },
  /* Publishers Menu End */

  /* Quality Menu Begin */
  {
    title: 'Quality',
    icon: 'CheckSquareIcon',
    component: () => import(
      '@/views/resources/Users/ReviewUser/CountUserNeedReview.vue'
    ),
    children: [
      {
        url: routerConfigs.FRAUD_REVIEW_STATISTICS.path,
        title: 'Fraud Review Statistics',
        icon: 'EyeIcon',
        route: routerConfigs.FRAUD_REVIEW_STATISTICS.name,
        sub_permissions: [
          {
            id: routerConfigs.FRAUD_REVIEW_STATISTICS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.HIGH_RISK_METRICS.path,
        title: 'High Risk Metrics',
        icon: 'AlertCircleIcon',
        route: routerConfigs.HIGH_RISK_METRICS.name,
        sub_permissions: [
          {
            id: routerConfigs.HIGH_RISK_METRICS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.SURVEY_ATTACKS.path,
        title: 'Survey Attacks',
        icon: 'CrosshairIcon',
        route: routerConfigs.SURVEY_ATTACKS.name,
        sub_permissions: [
          {
            id: routerConfigs.SURVEY_ATTACKS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.SURVEY_ATTACKS_APPS.path,
        title: 'Survey Attacks Apps',
        icon: 'AlertOctagonIcon',
        route: routerConfigs.SURVEY_ATTACKS_APPS.name,
        sub_permissions: [
          {
            id: routerConfigs.SURVEY_ATTACKS_APPS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.USER_REVIEWS.path,
        title: 'User Reviews',
        icon: 'UserXIcon',
        component: () => import(
          '@/views/resources/Users/ReviewUser/CountUserNeedReview.vue'
        ),
        route: routerConfigs.USER_REVIEWS.name,
        sub_permissions: [
          {
            id: routerConfigs.USER_REVIEWS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.USER_STATUS_CHANGES.path,
        title: 'User Status Changes',
        icon: 'UserCheckIcon',
        route: routerConfigs.USER_STATUS_CHANGES.name,
        sub_permissions: [
          {
            id: routerConfigs.USER_STATUS_CHANGES.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.SURVEY_ATTACKS_OPEN_ENDS.path,
        title: 'Survey Attacks Open-ends',
        icon: 'EditIcon',
        route: routerConfigs.SURVEY_ATTACKS_OPEN_ENDS.name,
        sub_permissions: [
          {
            id: routerConfigs.SURVEY_ATTACKS_OPEN_ENDS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.APP_COUNTRY_SOURCE_CONFIG.path,
        title: 'App-Country-Source Config',
        icon: 'AirplayIcon',
        route: routerConfigs.APP_COUNTRY_SOURCE_CONFIG.name,
        sub_permissions: [
          {
            id: routerConfigs.APP_COUNTRY_SOURCE_CONFIG.name,
            text: 'Main',
            main: true
          }
        ]
      }
    ]
  },
  /* Quality Menu End */

  /* Activity Menu Begin */
  {
    title: 'Activity',
    icon: 'ActivityIcon',
    children: [
      {
        url: routerConfigs.QUALIFICATIONS.path,
        title: 'Qualifications',
        icon: 'FilterIcon',
        route: routerConfigs.QUALIFICATIONS.name,
        sub_permissions: [
          {
            id: routerConfigs.QUALIFICATIONS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.COMPLETES.path,
        title: 'Completes',
        icon: 'CheckSquareIcon',
        route: routerConfigs.COMPLETES.name,
        sub_permissions: [
          {
            id: routerConfigs.COMPLETES.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.CLICKS.path,
        title: 'Clicks',
        icon: 'MousePointerIcon',
        route: routerConfigs.CLICKS.name,
        sub_permissions: [
          {
            id: routerConfigs.CLICKS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.FEEDBACK.path,
        title: 'Feedback',
        icon: 'MessageCircleIcon',
        route: routerConfigs.FEEDBACK.name,
        sub_permissions: [
          {
            id: routerConfigs.FEEDBACK.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.FIND_DATA.path,
        title: 'Find Data',
        icon: 'FileTextIcon',
        route: routerConfigs.FIND_DATA.name,
        sub_permissions: [
          {
            id: routerConfigs.FIND_DATA.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.MULTI_SEARCH_CLICK.path,
        title: 'Click Search',
        icon: 'SearchIcon',
        route: routerConfigs.MULTI_SEARCH_CLICK.name,
        sub_permissions: [
          {
            id: routerConfigs.MULTI_SEARCH_CLICK.name,
            text: 'Main',
            main: true
          }
        ]
      }
    ]
  },
  /* Activity Menu End */

  /* Users Menu Begin */
  {
    title: 'Users',
    icon: 'UsersIcon',
    component: () => import('@/views/click_tickets/partials/CountNotReviewed.vue'),
    children: [
      {
        url: routerConfigs.CHAT.name,
        title: 'Live Chat Support',
        icon: 'MessageSquareIcon',
        route: routerConfigs.CHAT.name,
        sub_permissions: [
          {
            id: routerConfigs.CHAT.name,
            text: 'Main',
            main: true
          },
          {
            id: 'manage_macros',
            text: 'Manage Macros'
          }
        ]
      },
      {
        url: routerConfigs.USERS.path,
        title: 'Users',
        icon: 'UsersIcon',
        route: routerConfigs.USERS.name,
        sub_permissions: [
          {
            id: routerConfigs.USERS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        title: 'User details',
        hidden: true,
        sub_permissions: [
          {
            id: routerConfigs.USER_DETAILS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.USER_BONUS_OFFERS.path,
        title: 'User Bonus Offers',
        icon: 'GiftIcon',
        route: routerConfigs.USER_BONUS_OFFERS.name,
        sub_permissions: [
          {
            id: routerConfigs.USER_BONUS_OFFERS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.USER_PROFILINGS.path,
        title: 'User Profilings',
        icon: 'UserCheckIcon',
        route: routerConfigs.USER_PROFILINGS.name,
        sub_permissions: [
          {
            id: routerConfigs.USER_PROFILINGS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.USER_PROFILING_LOOKUP.path,
        title: 'User Profiling Lookup',
        icon: 'EyeIcon',
        route: routerConfigs.USER_PROFILING_LOOKUP.name,
        sub_permissions: [
          {
            id: routerConfigs.USER_PROFILING_LOOKUP.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.USER_PROFILING_SEARCH.path,
        title: 'User Profiling Search',
        icon: 'UserXIcon',
        route: routerConfigs.USER_PROFILING_SEARCH.name,
        sub_permissions: [
          {
            id: routerConfigs.USER_PROFILING_SEARCH.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.USER_FILTER_SEARCH.path,
        title: 'User Filter Search',
        icon: 'FilterIcon',
        route: routerConfigs.USER_FILTER_SEARCH.name,
        sub_permissions: [
          {
            id: routerConfigs.USER_FILTER_SEARCH.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.CLICK_TICKETS.path,
        title: 'Click Tickets',
        icon: 'InboxIcon',
        component: () => import(
          '@/views/click_tickets/partials/CountNotReviewed.vue'
        ),
        route: routerConfigs.CLICK_TICKETS.name,
        sub_permissions: [
          {
            id: routerConfigs.CLICK_TICKETS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.MULTI_SEARCH_USER.path,
        title: 'User Search',
        icon: 'SearchIcon',
        route: routerConfigs.MULTI_SEARCH_USER.name,
        sub_permissions: [
          {
            id: routerConfigs.MULTI_SEARCH_USER.name,
            text: 'Main',
            main: true
          }
        ]
      }
    ]
  },
  /* Users Menu End */

  /* Config Menu Begin */
  {
    title: 'Config',
    icon: 'SettingsIcon',
    component: () => import(
      '@/views/resources/SystemAlerts/Nav/CountSystemAlertNeedReview.vue'
    ),
    children: [
      {
        url: routerConfigs.ADMINS.path,
        title: 'Admin',
        icon: 'UserPlusIcon',
        route: routerConfigs.ADMINS.name,
        sub_permissions: [
          {
            id: routerConfigs.ADMINS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.APP_ARTICLES.path,
        title: 'App Article',
        icon: 'BookIcon',
        route: routerConfigs.APP_ARTICLES.name,
        sub_permissions: [
          {
            id: routerConfigs.APP_ARTICLES.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.GEO_MAPPING.path,
        title: 'Geo Mapping',
        icon: 'MapIcon',
        route: routerConfigs.GEO_MAPPING.name,
        sub_permissions: [
          {
            id: routerConfigs.GEO_MAPPING.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.CONFIGS.path,
        title: 'System Configs',
        icon: 'SettingsIcon',
        route: routerConfigs.CONFIGS.name,
        sub_permissions: [
          {
            id: routerConfigs.CONFIGS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.SYSTEM_ALERTS.path,
        title: 'System alerts',
        icon: 'AlertTriangleIcon',
        route: routerConfigs.SYSTEM_ALERTS.name,
        component: () => import(
          '@/views/resources/SystemAlerts/Nav/CountSystemAlertNeedReview.vue'
        ),
        sub_permissions: [
          {
            id: routerConfigs.SYSTEM_ALERTS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.RESPONSE_STATUS.path,
        title: 'Response Status',
        icon: 'MessageSquareIcon',
        route: routerConfigs.RESPONSE_STATUS.name,
        sub_permissions: [
          {
            id: routerConfigs.RESPONSE_STATUS.name,
            text: 'Main',
            main: true
          }
        ]
      }
    ]
  },
  /* Config Menu End */

  /* Log Menu Begin */
  {
    title: 'Log',
    icon: 'ArchiveIcon',
    children: [
      {
        url: routerConfigs.CRONJOB_LOGS.path,
        title: 'Cronjob Logs',
        icon: 'CalendarIcon',
        route: routerConfigs.CRONJOB_LOGS.name,
        sub_permissions: [
          {
            id: routerConfigs.CRONJOB_LOGS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.DEVICE_RESULTS.path,
        title: 'Device Results',
        icon: 'SmartphoneIcon',
        route: routerConfigs.DEVICE_RESULTS.name,
        sub_permissions: [
          {
            id: routerConfigs.DEVICE_RESULTS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.POSTBACK_LOGS.path,
        title: 'Postback Logs',
        icon: 'CastIcon',
        route: routerConfigs.POSTBACK_LOGS.name,
        sub_permissions: [
          {
            id: routerConfigs.POSTBACK_LOGS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.PUBLISHER_API_REQUEST_LOG.path,
        title: 'Publisher API Request Logs',
        icon: 'UploadCloudIcon',
        route: routerConfigs.PUBLISHER_API_REQUEST_LOG.name,
        sub_permissions: [
          {
            id: routerConfigs.PUBLISHER_API_REQUEST_LOG.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.PUBLISHER_TRANSACTIONS.path,
        title: 'Publisher Transactions',
        icon: 'ShoppingBagIcon',
        route: routerConfigs.PUBLISHER_TRANSACTIONS.name,
        sub_permissions: [
          {
            id: routerConfigs.PUBLISHER_TRANSACTIONS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.SYSTEM_LOGS.path,
        title: 'System Logs',
        icon: 'CpuIcon',
        route: routerConfigs.SYSTEM_LOGS.name,
        sub_permissions: [
          {
            id: routerConfigs.SYSTEM_LOGS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.SURVEY_LIST_RELOADS.path,
        title: 'Survey List Reloads',
        icon: 'RotateCwIcon',
        route: routerConfigs.SURVEY_LIST_RELOADS.name,
        sub_permissions: [
          {
            id: routerConfigs.SURVEY_LIST_RELOADS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.TIMEZONE_RESULTS.path,
        title: 'Timezone Results',
        icon: 'ClockIcon',
        route: routerConfigs.TIMEZONE_RESULTS.name,
        sub_permissions: [
          {
            id: routerConfigs.TIMEZONE_RESULTS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.IP_LOG.path,
        title: 'IP Logs',
        icon: 'GitCommitIcon',
        route: routerConfigs.IP_LOG.name,
        sub_permissions: [
          {
            id: routerConfigs.IP_LOG.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.ADMIN_LOG.path,
        title: 'Admin Logs',
        icon: 'GitCommitIcon',
        route: routerConfigs.ADMIN_LOG.name,
        sub_permissions: [
          {
            id: routerConfigs.ADMIN_LOG.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.LATENCY_LOGS.path,
        title: 'Latency Logs',
        icon: 'ClockIcon',
        route: routerConfigs.LATENCY_LOGS.name,
        sub_permissions: [
          {
            id: routerConfigs.LATENCY_LOGS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        title: 'Router Log',
        icon: 'RepeatIcon',
        url: routerConfigs.ROUTER_LOGS.path,
        slug: 'router-logs',
        route: routerConfigs.ROUTER_LOGS.name,
        sub_permissions: [
          {
            id: routerConfigs.ROUTER_LOGS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.RECONCILIATION_LOGS.path,
        title: 'Reconciliation Logs',
        icon: 'DeleteIcon',
        route: routerConfigs.RECONCILIATION_LOGS.name,
        sub_permissions: [
          {
            id: routerConfigs.RECONCILIATION_LOGS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.SOURCE_EXTENSION.path,
        title: 'Extension',
        icon: 'LayersIcon',
        route: routerConfigs.SOURCE_EXTENSION.name,
        sub_permissions: [
          {
            id: routerConfigs.SOURCE_EXTENSION.name,
            text: 'Main',
            main: true
          }
        ]
      }
    ]
  },
  /* Log Menu End */

  /* Offer Menu Begin */
  {
    title: 'Offers',
    icon: 'PercentIcon',
    children: [
      {
        url: routerConfigs.OFFER_LIST.path,
        title: 'Offer List',
        icon: 'ListIcon',
        route: routerConfigs.OFFER_LIST.name,
        sub_permissions: [
          {
            id: routerConfigs.OFFER_LIST.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.OFFER_CLICKS.path,
        title: 'Clicks',
        icon: 'MousePointerIcon',
        route: routerConfigs.OFFER_CLICKS.name,
        sub_permissions: [
          {
            id: routerConfigs.OFFER_CLICKS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        title: 'Offer details',
        hidden: true,
        sub_permissions: [
          {
            id: routerConfigs.OFFER_DETAILS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.OFFER_EVENTS.path,
        title: 'Events',
        icon: 'CalendarIcon',
        route: routerConfigs.OFFER_EVENTS.name,
        sub_permissions: [
          {
            id: routerConfigs.OFFER_EVENTS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.OFFER_CLICK_REPORT.path,
        title: 'Click Report',
        icon: 'CalendarIcon',
        route: routerConfigs.OFFER_CLICK_REPORT.name,
        sub_permissions: [
          {
            id: routerConfigs.OFFER_CLICK_REPORT.name,
            text: 'Main',
            main: true
          }
        ]
      }
    ]
  }
  /* Offer Menu End */

  /* Chat Menu Begin */
  // TODO: temporary hidden in menu
  // {
  //   url: routerConfigs.CHAT.name,
  //   title: 'Live Chat Support',
  //   icon: 'MessageSquareIcon',
  //   route: routerConfigs.CHAT.name,
  //   sub_permissions: [
  //     {
  //       id: routerConfigs.CHAT.name,
  //       text: 'Main',
  //       main: true
  //     }
  //   ]
  // }
  /* Chat Menu End */

  // {
  //   url: routerConfigs.PUBLISHERS.path,
  //   title: 'Publishers',
  //   icon: 'UserIcon',
  //   route: routerConfigs.PUBLISHERS.name,
  //   sub_permissions: [
  //     {
  //       id: routerConfigs.PUBLISHERS.name,
  //       text: 'Main',
  //       main: true
  //     },
  //     {
  //       id: routerConfigs.PUBLISHER_DETAILS.name,
  //       text: 'Publisher details'
  //     }
  //   ]
  // },
  //
  // {
  //   url: routerConfigs.STATISTICS.path,
  //   title: 'Statistics',
  //   icon: 'BarChart2Icon',
  //   route: routerConfigs.STATISTICS.name,
  //   sub_permissions: [
  //     {
  //       id: routerConfigs.STATISTICS.name,
  //       text: 'Main',
  //       main: true
  //     }
  //   ]
  // },
  //
  //
  // {
  //   url: routerConfigs.PUBLISHERS.path,
  //   title: 'Publishers',
  //   icon: 'UserIcon',
  //   route: routerConfigs.PUBLISHERS.name,
  //   sub_permissions: [
  //     {
  //       id: routerConfigs.PUBLISHERS.name,
  //       text: 'Main',
  //       main: true
  //     },
  //     {
  //       id: routerConfigs.PUBLISHER_DETAILS.name,
  //       text: 'Publisher details'
  //     }
  //   ]
  // },
  // {
  //   url: routerConfigs.APPS.path,
  //   title: 'Apps',
  //   icon: 'CopyIcon',
  //   route: routerConfigs.APPS.name,
  //   sub_permissions: [
  //     {
  //       id: routerConfigs.APPS.name,
  //       text: 'Main',
  //       main: true
  //     },
  //     {
  //       id: routerConfigs.APP_DETAILS.name,
  //       text: 'App details'
  //     }
  //   ]
  // },
  // {
  //   url: routerConfigs.PAYMENTS.path,
  //   title: 'Payments',
  //   icon: 'DollarSignIcon',
  //   route: routerConfigs.PAYMENTS.name,
  //   sub_permissions: [
  //     {
  //       id: routerConfigs.PAYMENTS.name,
  //       text: 'Main',
  //       main: true
  //     }
  //   ]
  // },
  //
  //
  // {
  //   title: 'Logs',
  //   icon: 'DatabaseIcon',
  //   children: [
  //     {
  //       url: routerConfigs.CRONJOB_LOGS.path,
  //       title: 'Cronjob logs',
  //       icon: 'DatabaseIcon',
  //       route: routerConfigs.CRONJOB_LOGS.name,
  //       sub_permissions: [
  //         {
  //           id: routerConfigs.CRONJOB_LOGS.name,
  //           text: 'Main',
  //           main: true
  //         }
  //       ]
  //     },
  //     {
  //       url: routerConfigs.DEV_LOGS.path,
  //       title: 'Dev Logs',
  //       icon: 'DatabaseIcon',
  //       route: routerConfigs.DEV_LOGS.name,
  //       sub_permissions: [
  //         {
  //           id: routerConfigs.DEV_LOGS.name,
  //           text: 'Main',
  //           main: true
  //         }
  //       ]
  //     },
  //     {
  //       url: routerConfigs.SHELL_LOGS.path,
  //       title: 'Shell Logs',
  //       icon: 'DatabaseIcon',
  //       route: routerConfigs.SHELL_LOGS.name,
  //       sub_permissions: [
  //         {
  //           id: routerConfigs.SHELL_LOGS.name,
  //           text: 'Main',
  //           main: true
  //         }
  //       ]
  //     },
  //
  //
  //     {
  //       url: routerConfigs.IPLOGSONE.path,
  //       title: 'Ip Logs One',
  //       icon: 'LayoutIcon',
  //       route: routerConfigs.IPLOGSONE.name,
  //       sub_permissions: [
  //         {
  //           id: routerConfigs.IPLOGSONE.name,
  //           text: 'Main',
  //           main: true
  //         }
  //       ]
  //     },
  //     {
  //       url: routerConfigs.IPLOGSTWO.path,
  //       title: 'Ip Logs Two',
  //       icon: 'LayoutIcon',
  //       route: routerConfigs.IPLOGSTWO.name,
  //       sub_permissions: [
  //         {
  //           id: routerConfigs.IPLOGSTWO.name,
  //           text: 'Main',
  //           main: true
  //         }
  //       ]
  //     },
  //     {
  //       url: routerConfigs.IPLOGSTHREE.path,
  //       title: 'Ip Logs Three',
  //       icon: 'LayoutIcon',
  //       route: routerConfigs.IPLOGSTHREE.name,
  //       sub_permissions: [
  //         {
  //           id: routerConfigs.IPLOGSTHREE.name,
  //           text: 'Main',
  //           main: true
  //         }
  //       ]
  //     },
  //
  //     {
  //       url: routerConfigs.RESEARCH_DEFENDER_LOG.path,
  //       title: 'Research Defender Logs',
  //       icon: 'LayoutIcon',
  //       route: routerConfigs.RESEARCH_DEFENDER_LOG.name,
  //       sub_permissions: [
  //         {
  //           id: routerConfigs.RESEARCH_DEFENDER_LOG.name,
  //           text: 'Main',
  //           main: true
  //         }
  //       ]
  //     }
  //   ]
  // }
]
