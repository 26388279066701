import baseRouterConfigs from './router-configs/baseRouterConfigs'
import poRouterConfigs from './router-configs/poRouterConfigs'
import fsRouterConfigs from './router-configs/fsRouterConfigs'
import hcRouterConfigs from './router-configs/hcRouterConfigs'
import psaRouterConfigs from './router-configs/psaRouterConfigs'
import tsRouterConfigs from './router-configs/tsRouterConfigs'

export function getFilteredRoutes (appType = 'default') {
  // Combine all routes from configs
  const allRoutes = Object.values({
    ...baseRouterConfigs,
    ...poRouterConfigs,
    ...fsRouterConfigs,
    ...hcRouterConfigs,
    ...psaRouterConfigs,
    ...tsRouterConfigs
  })

  return allRoutes.filter((route) => {
    const appMeta = route.meta?.apps
    const isExternalPage = route.meta?.loading === false || route.path === '*'

    if (appType === 'default' || isExternalPage) return true

    if (!appMeta) return false

    return appMeta.includes(appType)
  })
}