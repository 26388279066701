export default {
  PO_COHORT_ANALYSIS: {
    path: '/po-cohort-analysis',
    name: 'po_cohort_analysis',
    meta: {
      requiresAuth: true
    },
    component: () => import('@po_views/CohortAnalysis/CohortAnalysisIndex.vue')
  },
  PO_COHORT_ANALYSIS_EXPORT: {
    type: 'function',
    path: 'po_cohort_analysis_export',
    name: 'po_cohort_analysis_export',
    permission: 'po_cohort_analysis_export',
    meta: {
      requiresAuth: true
    }
  },
  PO_AFFILIATE_PARTNERS: {
    path: '/po-affiliate-partners',
    name: 'po_affiliate_partners',
    meta: {
      requiresAuth: true
    },
    component: () => import('@po_views/AffiliatePartners/AffiliatePartnersIndex')
  },
  PO_USERS: {
    path: '/po-users',
    name: 'po_users',
    meta: {
      requiresAuth: true
    },
    component: () => import('@po_views/Users/UsersIndex')
  },
  PO_USERS_EXPORT: {
    type: 'function',
    path: 'po_users_export',
    name: 'po_users_export',
    permission: 'po_users_export',
    meta: {
      requiresAuth: true
    }
  },
  PO_USER_DETAILS: {
    path: '/po-user/:userId',
    name: 'po_user_details',
    meta: {
      requiresAuth: true,
      quickLink: true,
      project: 'po',
      title: 'User Details',
      order: 0,
      main: true
    },
    component: () => import('@po_views/Users/UserDetails')
  },
  PO_TRANSACTIONS: {
    path: '/po-transactions',
    name: 'po_transactions',
    meta: {
      requiresAuth: true,
      quickLink: true,
      project: 'po',
      title: 'Transactions',
      order: 1
    },
    component: () => import('@po_views/Transactions/TransactionsIndex')
  },
  PO_CLAIMS: {
    path: '/po-claims',
    name: 'po_claims',
    meta: {
      requiresAuth: true,
      quickLink: true,
      project: 'po',
      title: 'Claims',
      order: 2
    },
    component: () => import('@po_views/Claims/ClaimsIndex')
  },
  PO_CONFIG_PAYOUT_CONFIRMATION: {
    path: '/po-config-payout-confirmation',
    name: 'po_config_payout_confirmation',
    meta: {
      requiresAuth: true
    },
    component: () => import('@po_views/Configs/PayoutConfirmations/PayoutConfirmationsIndex')
  },
  PO_LEADERBOARDS: {
    path: '/po-leaderboards',
    name: 'po_leaderboards',
    meta: {
      requiresAuth: true
    },
    component: () => import('@po_views/Leaderboards/LeaderboardsIndex')
  },
  PO_TRIGGERS: {
    path: '/po-triggers',
    name: 'po_triggers',
    meta: {
      requiresAuth: true
    },
    component: () => import('@po_views/Triggers/TriggersIndex')
  },
  PO_PERFORMANCE: {
    path: '/po-performance',
    name: 'po_performance',
    meta: {
      requiresAuth: true
    },
    component: () => import('@po_views/Performance/PerformanceIndex')
  },
  PO_PERFORMANCE_EXPORT: {
    type: 'function',
    path: 'po_performance_export',
    name: 'po_performance_export',
    permission: 'po_performance_export',
    meta: {
      requiresAuth: true
    }
  },
  PO_VIP_USERS: {
    path: '/po-vip-users',
    name: 'po_vip_users',
    meta: {
      requiresAuth: true
    },
    component: () => import('@po_views/Users/UsersVIP')
  },
  PO_EMAILS: {
    path: '/po-emails',
    name: 'po_emails',
    meta: {
      requiresAuth: true,
      quickLink: true,
      project: 'po',
      title: 'E-Mails',
      order: 3
    },
    component: () => import('@po_views/Emails/EmailsIndex')
  },
  PO_REWARDS: {
    path: '/po-rewards',
    name: 'po_rewards',
    meta: {
      requiresAuth: true
    },
    component: () => import('@po_views/Rewards')
  },
  PO_OFFER_WALL_PERFORMANCE: {
    path: '/po-offerwall-performance',
    name: 'po_offer_wall_performance',
    meta: {
      requiresAuth: true,
      quickLink: true,
      project: 'po',
      title: 'Offerwall Performance',
      order: 6
    },
    component: () => import('@po_views/OfferWallPerformance')
  },
  PO_BONUS_CODES: {
    path: '/po-bonus-codes',
    name: 'po_bonus_codes',
    meta: {
      requiresAuth: true
    },
    component: () => import('@po_views/BonusCodes/BonusCodesIndex.vue')
  },
  PO_EMAIL_STATISTICS: {
    path: '/po-email-statistics',
    name: 'po_email_statistics',
    meta: {
      requiresAuth: true
    },
    component: () => import('@po_views/Emails/EmailStatisticsIndex.vue')
  },
  PO_BONUS_CODE_REDEMPTIONS: {
    path: '/po-bonus-code-redemptions',
    name: 'po_bonus_code_redemptions',
    meta: {
      requiresAuth: true,
      quickLink: true,
      project: 'po',
      title: 'Bonus Codes',
      order: 4
    },
    component: () => import('@po_views/BonusCodeRedemptions')
  },
  PO_BOGDAN_BONUS: {
    path: '/po-bogdan-bonus',
    name: 'po_bogdan_bonus',
    meta: {
      requiresAuth: true
    },
    component: () => import('@po_views/BogdanBonus')
  },
  PO_VALUE_REPORT: {
    path: '/po-value-report',
    name: 'po_value_report',
    meta: {
      requiresAuth: true
    },
    component: () => import('@po_views/ValueReport')
  },
  PO_LEVELS: {
    path: '/po-levels',
    name: 'po_levels',
    meta: {
      requiresAuth: true
    },
    component: () => import('@po_views/Level')
  },
  PO_LOG_SPEND: {
    path: '/po-log-spend',
    name: 'po_log_spend',
    meta: {
      requiresAuth: true
    },
    component: () => import('@po_views/LogSpend')
  },
  PO_SETTINGS: {
    path: '/po-settings',
    name: 'po_settings',
    meta: {
      requiresAuth: true
    },
    component: () => import('@po_views/Settings')
  }
}
