<template>
  <div class="card border-2 border-black mb-4">
    <h5 class="p-1">High-Risk User Profiling</h5>
    <b-table
        v-if="userProfiling && userProfiling.length"
        striped
        hover
        :fields="fields"
        :items="userProfiling"
    >
      <!-- First Answer ID -->
      <template #cell(first_answer_id)="data">
        {{ data.item.first_answer_id }}
        <span v-if="data.item.first_question_lang || data.item.first_answer_lang">
          - {{ data.item.first_question_lang }}
          <b>{{ data.item.first_answer_lang }}</b>
        </span>
      </template>

      <!-- Second Answer ID -->
      <template #cell(second_answer_id)="data">
        {{ data.item.second_answer_id }}
        <span v-if="data.item.second_question_lang || data.item.second_answer_lang">
          - {{ data.item.second_question_lang }}
          <b>{{ data.item.second_answer_lang }}</b>
        </span>
      </template>

      <!-- Banned Percentage -->
      <template #cell(banned_percentage)="data">
        {{ data.value | formatPercent2Decimal }}
      </template>

      <!-- Total Users -->
      <template #cell(total_users)="data">
        <span class="text-primary" @click="showDetails(data.item, 'total')">
          {{ data.value }}
        </span>
      </template>

      <!-- Banned Users -->
      <template #cell(banned_users)="data">
        <span class="text-primary" @click="showDetails(data.item, 'banned')">
          {{ data.value }}
        </span>
      </template>

      <!-- Activated Users -->
      <template #cell(activated_users)="data">
        <span class="text-primary" @click="showDetails(data.item, 'activated')">
          {{ data.item.total_users - data.item.banned_users }}
        </span>
      </template>
    </b-table>
    <div v-else class="text-center pb-1">No data</div>

    <b-modal size="lg" ref="highRiskUserProfilingDetailsModal" hide-footer>
      <HighRiskUserProfilingDetails :answerId1="currentAnswerId1" :answerId2="currentAnswerId2" :type="currentType" />
    </b-modal>
  </div>
</template>

<script>
import HighRiskUserProfilingDetails from '@/views/resources/IpLogs/HighRiskUserProfilingDetails.vue'

export default {
  name: 'HighRiskUserProfilingTable',
  components: {HighRiskUserProfilingDetails},
  props: {
    userProfiling: {
      type: Array,
      required: true
    },
    fields: {
      type: Array,
      default: () => [
        'first_answer_id',
        'second_answer_id',
        'total_users',
        'banned_users',
        'activated_users',
        'banned_percentage'
      ]
    }
  },
  data () {
    return {
      currentAnswerId1: null,
      currentAnswerId2: null,
      currentType: null
    }
  },
  methods: {
    showDetails (item, type) {
      this.currentAnswerId1 = item.first_answer_id
      this.currentAnswerId2 = item.second_answer_id
      this.currentType = type

      this.$refs['highRiskUserProfilingDetailsModal'].show()
    }
  }
}
</script>
