//Sub permission: check \App\Lib\AdminPermissions.php
import routerConfigs from '../router-configs/fsRouterConfigs'

export default [
  {
    title: 'Five Surveys',
    icon: 'HexagonIcon',
    children: [
      {
        url: routerConfigs.FS_BOGDAN_BONUS.path,
        title: 'Bogdan Bonus',
        icon: 'PercentIcon',
        route: routerConfigs.FS_BOGDAN_BONUS.name,
        sub_permissions: [
          {
            id: routerConfigs.FS_BOGDAN_BONUS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.FS_AFFILIATE_PARTNERS.path,
        title: 'Affiliate Partners',
        icon: 'UserPlusIcon',
        route: routerConfigs.FS_AFFILIATE_PARTNERS.name,
        sub_permissions: [
          {
            id: routerConfigs.FS_AFFILIATE_PARTNERS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.FS_CLAIMS.path,
        title: 'Claims',
        icon: 'CheckSquareIcon',
        route: routerConfigs.FS_CLAIMS.name,
        sub_permissions: [
          {
            id: routerConfigs.FS_CLAIMS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.FS_COHORT_ANALYSIS.path,
        title: 'Cohort Analysis',
        icon: 'LayoutIcon',
        route: routerConfigs.FS_COHORT_ANALYSIS.name,
        sub_permissions: [
          {
            id: routerConfigs.FS_COHORT_ANALYSIS.name,
            text: 'Main',
            main: true
          },
          {
            id: 'fs_cohort_analysis_export',
            text: 'FS Cohort Analysis Export',
            always_available_for_ids: [1, 9]
          }
        ]
      },
      {
        url: routerConfigs.FS_COHORT_ANALYSIS2.path,
        title: 'Cohort Analysis New',
        icon: 'LayoutIcon',
        route: routerConfigs.FS_COHORT_ANALYSIS2.name,
        sub_permissions: [
          {
            id: routerConfigs.FS_COHORT_ANALYSIS2.name,
            text: 'Main',
            main: true
          },
          {
            id: 'fs_cohort_analysis_export',
            text: 'FS Cohort Analysis Export',
            always_available_for_ids: [1, 9]
          }
        ],
        always_available_for_ids: [1]
      },
      {
        url: routerConfigs.FS_EMAILS.path,
        title: 'E-Mails',
        icon: 'MailIcon',
        route: routerConfigs.FS_EMAILS.name,
        sub_permissions: [
          {
            id: routerConfigs.FS_EMAILS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.FS_EMAIL_STATISTICS.path,
        title: 'E-Mail Statistics',
        icon: 'BarChart2Icon',
        route: routerConfigs.FS_EMAIL_STATISTICS.name,
        sub_permissions: [
          {
            id: routerConfigs.FS_EMAIL_STATISTICS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.FS_LOG_SPEND.path,
        title: 'Log Spend',
        icon: 'DollarSignIcon',
        route: routerConfigs.FS_LOG_SPEND.name,
        sub_permissions: [
          {
            id: routerConfigs.FS_LOG_SPEND.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.FS_PERFORMANCE.path,
        title: 'Performance',
        icon: 'PieChartIcon',
        route: routerConfigs.FS_PERFORMANCE.name,
        sub_permissions: [
          {
            id: routerConfigs.FS_PERFORMANCE.name,
            text: 'Main',
            main: true
          },
          {
            id: 'fs_performance_export',
            text: 'FS Performance Export',
            always_available_for_ids: [1, 9]
          }
        ]
      },
      {
        url: routerConfigs.FS_REWARDS.path,
        title: 'Rewards',
        icon: 'AwardIcon',
        route: routerConfigs.FS_REWARDS.name,
        sub_permissions: [
          {
            id: routerConfigs.FS_REWARDS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.FS_SETTINGS.path,
        title: 'Settings',
        icon: 'SettingsIcon',
        route: routerConfigs.FS_SETTINGS.name,
        always_available_for_ids: [1, 5, 6],
        sub_permissions: [
          {
            id: routerConfigs.FS_SETTINGS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.FS_TRANSACTIONS.path,
        title: 'Transactions',
        icon: 'DatabaseIcon',
        route: routerConfigs.FS_TRANSACTIONS.name,
        sub_permissions: [
          {
            id: routerConfigs.FS_TRANSACTIONS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.FS_TRIGGERS.path,
        title: 'Triggers',
        icon: 'DiscIcon',
        route: routerConfigs.FS_TRIGGERS.name,
        sub_permissions: [
          {
            id: routerConfigs.FS_TRIGGERS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.FS_USERS.path,
        title: 'Users',
        icon: 'UsersIcon',
        route: routerConfigs.FS_USERS.name,
        sub_permissions: [
          {
            id: routerConfigs.FS_USERS.name,
            text: 'Main',
            main: true
          },
          {
            id: 'fs_users_export',
            text: 'FS Users Export',
            always_available_for_ids: [1, 9]
          }
        ]
      },
      {
        title: 'FS User details',
        hidden: true,
        sub_permissions: [
          {
            id: routerConfigs.FS_USER_DETAILS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.FS_COMPLETES.path,
        title: 'Completes',
        icon: 'CheckSquareIcon',
        route: routerConfigs.FS_COMPLETES.name,
        sub_permissions: [
          {
            id: routerConfigs.FS_COMPLETES.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.FS_OFFER_WALL_PERFORMANCE.path,
        title: 'Offerwall Performance',
        icon: 'CpuIcon',
        route: routerConfigs.FS_OFFER_WALL_PERFORMANCE.name,
        sub_permissions: [
          {
            id: routerConfigs.FS_OFFER_WALL_PERFORMANCE.name,
            text: 'Main',
            main: true
          }
        ]
      }
    ]
  }
]
