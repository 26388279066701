export default {
  TS_CLAIMS: {
    path: '/ts-claims',
    name: 'ts_claims',
    meta: {
      requiresAuth: true,
      quickLink: true,
      project: 'ts',
      title: 'Claims',
      order: 2
    },
    component: () => import('@ts_views/Claims/ClaimsIndex')
  },
  TS_COHORT_ANALYSIS: {
    path: '/ts-cohort-analysis',
    name: 'ts_cohort_analysis',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ts_views/CohortAnalysis/CohortAnalysisIndex.vue')
  },
  TS_COHORT_ANALYSIS_EXPORT: {
    type: 'function',
    path: 'ts_cohort_analysis_export',
    name: 'ts_cohort_analysis_export',
    permission: 'ts_cohort_analysis_export',
    meta: {
      requiresAuth: true
    }
  },
  TS_EMAILS: {
    path: '/ts-emails',
    name: 'ts_emails',
    meta: {
      requiresAuth: true,
      quickLink: true,
      project: 'ts',
      title: 'E-Mails',
      order: 3
    },
    component: () => import('@ts_views/Emails/EmailsIndex')
  },
  TS_PERFORMANCE: {
    path: '/ts-performance',
    name: 'ts_performance',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ts_views/Performance/PerformanceIndex')
  },
  TS_PERFORMANCE_EXPORT: {
    type: 'function',
    path: 'ts_performance_export',
    name: 'ts_performance_export',
    permission: 'ts_performance_export',
    meta: {
      requiresAuth: true
    }
  },
  TS_REWARDS: {
    path: '/ts-rewards',
    name: 'ts_rewards',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ts_views/Rewards')
  },
  TS_TRANSACTIONS: {
    path: '/ts-transactions',
    name: 'ts_transactions',
    meta: {
      requiresAuth: true,
      quickLink: true,
      project: 'ts',
      title: 'Transactions',
      order: 1
    },
    component: () => import('@ts_views/Transactions/TransactionsIndex')
  },
  TS_AFFILIATE_PARTNERS: {
    path: '/ts-affiliate-partners',
    name: 'ts_affiliate_partners',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ts_views/AffiliatePartners/AffiliatePartnersIndex')
  },
  TS_USERS: {
    path: '/ts-users',
    name: 'ts_users',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ts_views/Users/UsersIndex')
  },
  TS_USERS_EXPORT: {
    type: 'function',
    path: 'ts_users_export',
    name: 'ts_users_export',
    permission: 'ts_users_export',
    meta: {
      requiresAuth: true
    }
  },
  TS_USER_DETAILS: {
    path: '/ts-user/:userId',
    name: 'ts_user_details',
    meta: {
      requiresAuth: true,
      quickLink: true,
      project: 'ts',
      title: 'User Details',
      order: 0,
      main: true
    },
    component: () => import('@ts_views/Users/UserDetails')
  },
  TS_TRIGGERS: {
    path: '/ts-triggers',
    name: 'ts_triggers',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ts_views/Triggers/TriggersIndex')
  },
  TS_LOG_SPEND: {
    path: '/ts-log-spend',
    name: 'ts_log_spend',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ts_views/LogSpend')
  },
  TS_OFFER_WALL_PERFORMANCE: {
    path: '/ts-offerwall-performance',
    name: 'ts_offer_wall_performance',
    meta: {
      requiresAuth: true,
      quickLink: true,
      project: 'ts',
      title: 'Offerwall Performance',
      order: 6
    },
    component: () => import('@ts_views/OfferWallPerformance')
  },
  TS_SETTINGS: {
    path: '/ts-settings',
    name: 'ts_settings',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ts_views/Settings')
  }
}
