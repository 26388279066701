export default {

  HC_CLAIMS: {
    path: '/hc-claims',
    name: 'hc_claims',
    meta: {
      requiresAuth: true,
      quickLink: true,
      project: 'hc',
      title: 'Claims',
      order: 2
    },
    component: () => import('@hc_views/Claims/ClaimsIndex')
  },
  HC_COHORT_ANALYSIS: {
    path: '/hc-cohort-analysis',
    name: 'hc_cohort_analysis',
    meta: {
      requiresAuth: true
    },
    component: () => import('@hc_views/CohortAnalysis/CohortAnalysisIndex.vue')
  },
  HC_COHORT_ANALYSIS_EXPORT: {
    type: 'function',
    path: 'hc_cohort_analysis_export',
    name: 'hc_cohort_analysis_export',
    permission: 'hc_cohort_analysis_export',
    meta: {
      requiresAuth: true
    }
  },
  HC_EMAILS: {
    path: '/hc-emails',
    name: 'hc_emails',
    meta: {
      requiresAuth: true,
      quickLink: true,
      project: 'hc',
      title: 'E-Mails',
      order: 3
    },
    component: () => import('@hc_views/Emails/EmailsIndex')
  },
  HC_PERFORMANCE: {
    path: '/hc-performance',
    name: 'hc_performance',
    meta: {
      requiresAuth: true
    },
    component: () => import('@hc_views/Performance/PerformanceIndex')
  },
  HC_PERFORMANCE_EXPORT: {
    type: 'function',
    path: 'hc_performance_export',
    name: 'hc_performance_export',
    permission: 'hc_performance_export',
    meta: {
      requiresAuth: true
    }
  },
  HC_REWARDS: {
    path: '/hc-rewards',
    name: 'hc_rewards',
    meta: {
      requiresAuth: true
    },
    component: () => import('@hc_views/Rewards')
  },
  HC_TRANSACTIONS: {
    path: '/hc-transactions',
    name: 'hc_transactions',
    meta: {
      requiresAuth: true,
      quickLink: true,
      project: 'hc',
      title: 'Transactions',
      order: 1
    },
    component: () => import('@hc_views/Transactions/TransactionsIndex')
  },
  HC_LEVELS: {
    path: '/hc-levels',
    name: 'hc_levels',
    meta: {
      requiresAuth: true
    },
    component: () => import('@hc_views/Level')
  },
  HC_LEADERBOARDS: {
    path: '/hc-leaderboards',
    name: 'hc_leaderboards',
    meta: {
      requiresAuth: true
    },
    component: () => import('@hc_views/Leaderboards/LeaderboardsIndex')
  },
  HC_AFFILIATE_PARTNERS: {
    path: '/hc-affiliate-partners',
    name: 'hc_affiliate_partners',
    meta: {
      requiresAuth: true
    },
    component: () => import('@hc_views/AffiliatePartners/AffiliatePartnersIndex')
  },
  HC_USERS: {
    path: '/hc-users',
    name: 'hc_users',
    meta: {
      requiresAuth: true
    },
    component: () => import('@hc_views/Users/UsersIndex')
  },
  HC_USERS_EXPORT: {
    type: 'function',
    path: 'hc_users_export',
    name: 'hc_users_export',
    permission: 'hc_users_export',
    meta: {
      requiresAuth: true
    }
  },
  HC_USER_DETAILS: {
    path: '/hc-user/:userId',
    name: 'hc_user_details',
    meta: {
      requiresAuth: true,
      quickLink: true,
      project: 'hc',
      title: 'User Details',
      order: 0,
      main: true
    },
    component: () => import('@hc_views/Users/UserDetails')
  },
  HC_TRIGGERS: {
    path: '/hc-triggers',
    name: 'hc_triggers',
    meta: {
      requiresAuth: true
    },
    component: () => import('@hc_views/Triggers/TriggersIndex')
  },
  HC_LOG_SPEND: {
    path: '/hc-log-spend',
    name: 'hc_log_spend',
    meta: {
      requiresAuth: true
    },
    component: () => import('@hc_views/LogSpend')
  },
  HC_OFFER_WALL_PERFORMANCE: {
    path: '/hc-offerwall-performance',
    name: 'hc_offer_wall_performance',
    meta: {
      requiresAuth: true,
      quickLink: true,
      project: 'hc',
      title: 'Offerwall Performance',
      order: 6
    },
    component: () => import('@hc_views/OfferWallPerformance')
  },
  HC_SETTINGS: {
    path: '/hc-settings',
    name: 'hc_settings',
    meta: {
      requiresAuth: true
    },
    component: () => import('@hc_views/Settings')
  }
}
